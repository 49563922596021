import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import { message } from "antd";
import { StorageUploader } from "../../../data/StorageUploader";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";

const CreateOrEditVideo = (props) => {
    let {
        stage_id, propsSetView, getYandexKey, videoKey,
        taskKey, createVideo, editVideo, isEditComponent = false
    } = props;

    const variable_3 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_3"});
    const variable_18 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_18"});
    const variable_24 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_24"});
    const variable_26 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_26"});
    const variable_27 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_27"});
    const variable_28 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_28"});
    const variable_29 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_29"});
    const variable_31 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_31"});
    const variable_32 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_32"});
    const variable_33 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_33"});
    const variable_34 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_34"});
    const variable_76 = variableLanguage({keyPage: "courseManageEditLesson", keyVariable: "variable_76"});

    const [valuesObject, setValuesObject] = useState({
        stage: stage_id,
        theory_type: "V",
        title: '',
        text: '',
        youtube: '',
        url_refs: '',
        video_file: '',
        video_file_name: '',
        task_file: '',
        task_file_name: ''
    })
    const [fileProcess, setFileProcess] = useState(null);
    const [activeDemoVideo, setActiveDemoVideo] = useState(0);

    useEffect(() => {
        getYandexKey('V', 'videoKey');
        getYandexKey('V', 'taskKey');
    }, [])
    useEffect(() => {
        if (props.stageItems !== undefined) {
            setValuesObject(props.stageItems);
            if (!props.stageItems.youtube && props.stageItems.video_file) {
                setActiveDemoVideo(1)
            }
        }
    }, [props.stageItems])
    return (
        <>
            <div className="label" id={'CreateOrEditVideo'.toUpperCase()}>{variable_24}</div>
            <input
                value={valuesObject.title}
                onChange={(e) => setValuesObject(prev => ({...prev, ['title']: e.target.value}))}
                placeholder={variable_26}
                maxLength={99}
            />
            <div className="demo_row">
                <span>{variable_27}</span>
                <div className="group">
                    <div
                        className={activeDemoVideo === 0 ? "active" : ""}
                        onClick={() => setActiveDemoVideo(0)}
                    >
                        {variable_28}
                    </div>
                    <div
                        className={activeDemoVideo === 1 ? "active" : ""}
                        onClick={() => setActiveDemoVideo(1)}
                    >
                        {variable_29}
                    </div>
                </div>
            </div>
            {activeDemoVideo === 0
                ? <input
                    value={valuesObject.youtube}
                    onChange={(e) => {
                        setValuesObject(prev => ({...prev, ['youtube']: e.target.value}))
                        setValuesObject(prev => ({...prev, ['video_file']: ''}))
                        setValuesObject(prev => ({...prev, ['video_file_name']: ''}))
                    }}
                    maxLength={199}
                    placeholder={variable_28}
                />
                : <>
                    <StorageUploader
                        yandexKey={videoKey}
                        count={1}
                        fileTypes={"video/*"}
                        editable={true}
                        setFileName={(name) => {
                            setValuesObject(prev => ({...prev, ['video_file_name']: name}))
                            setValuesObject(prev => ({...prev, ['youtube']: ''}))
                        }}
                        setFileListProps={(value) => {
                            setFileProcess(value);
                            setValuesObject(prev => ({...prev, ['video_file_name']: value[0].name}))
                        }}
                        fileWillBeUpload={(fileName) => {
                            setValuesObject(prev =>
                                ({
                                    ...prev,
                                    ['video_file']: 'https://storage.yandexcloud.net/skill-storage/' + videoKey + "/" + fileName.replace(/\s+/g, "_")
                                })
                            )
                        }}
                        firstRenderFile={props.stageItems !== undefined && valuesObject.task_file && valuesObject.task_file_name
                            ? [{
                                uid: valuesObject.video_file,
                                name: valuesObject.video_file_name,
                                status: "done"
                            }]
                            : false
                        }
                        removeFile={() => {
                            setValuesObject(prev => ({...prev, ['video_file']: ''}))
                            setValuesObject(prev => ({...prev, ['video_file_name']: ''}))
                        }}
                    />
                </>
            }
            {activeDemoVideo === 0 && valuesObject.youtube
                ? <div className="player-wrapper">
                    <ReactPlayer
                        className="react-player"
                        controls={true}
                        url={valuesObject.youtube}
                        width="100%"
                        height="100%"
                    />
                </div>
                : <></>
            }

            <div className="label">{variable_31}</div>
            <textarea
                value={valuesObject.text}
                onChange={(e) => setValuesObject(prev => ({...prev, ['text']: e.target.value}))}
                placeholder={variable_26}
                rows="5"
            />
            <div className="label" style={{marginTop: -5}}>{variable_32}</div>
            <input
                value={valuesObject.url_refs}
                onChange={(e) => setValuesObject(prev => ({...prev, ['url_refs']: e.target.value}))}
                placeholder={variable_26}
            />
            <div className="label">{variable_76}</div>
            <div>
                <StorageUploader
                    yandexKey={taskKey}
                    count={1}
                    setFileName={(name) => setValuesObject(prev => ({...prev, ['task_file_name']: name}))}
                    fileWillBeUpload={(fileName) => {
                        setValuesObject(prev =>
                            ({
                                ...prev,
                                ['task_file']: 'https://storage.yandexcloud.net/skill-storage/' + taskKey + "/" + fileName.replace(/\s+/g, "_")
                            })
                        )
                    }}
                    setFileListProps={(value) => {
                        setFileProcess(value);
                        setValuesObject(prev => ({...prev, ['task_file_name']: value[0].name}))
                    }}
                    firstRenderFile={valuesObject.task_file && valuesObject.task_file_name ?
                        [{
                            uid: valuesObject.task_file,
                            name: valuesObject.task_file_name,
                            status: "done"
                        }]
                        : false
                    }
                    removeFile={() => {
                        setValuesObject(prev => ({...prev, ['task_file']: ''}))
                        setValuesObject(prev => ({...prev, ['task_file_name']: ''}))
                    }}
                />
            </div>
            <div className="group_buttons">
                <div
                    className={fileProcess !== null && fileProcess[0].percent !== 100 ? "btn_save disabled" : "btn_save"}
                    onClick={() => {
                        if (fileProcess !== null && fileProcess[0].percent !== 100) return;
                        if (!valuesObject.title) return message.error(variable_3);
                        if (isEditComponent) {
                            valuesObject.stage = stage_id;
                            return editVideo(valuesObject)
                        }
                        createVideo(valuesObject)
                    }}
                >
                    {isEditComponent ? variable_33 : variable_18}
                </div>
                <div
                    className="btn_cansel btn_save"
                    onClick={() => propsSetView()}
                >
                    {variable_34}
                </div>
            </div>
        </>
    )
}

export default CreateOrEditVideo;
