import React, { useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    changeLanguage, setActiveModules, setEndTrialDate,
    setListModules, setStatusTrial
} from "./redux/actions/actions";
import requests from "./backoffice/axios/requests";
import cn from 'classnames';

import { setUser, setUserSkills, setUserTrajectory } from "./redux/actions/userActions";
import Login from "./backoffice/pages/Login/login";
import Register from "./backoffice/pages/Register/register";
import Profile from "./backoffice/pages/Profile/Profile";
import MyCourse from "./backoffice/pages/MyCourse/MyCourse";
import CourseManageNew from "./backoffice/pages/CourseManage/CourseManage";
import AddCourseNew from "./backoffice/pages/CurrentCourse/CurrentCourse";
import InnerRequestMember from "./backoffice/pages/InnerRequestMember/InnerRequestMember";
import GroupsList from "./backoffice/pages/GroupsList/GroupsList";
import ForgotPassword from "./backoffice/pages/Login/forgot_password";
import Notification from "./backoffice/pages/Notification/Notification";
import ViewItemJournal from "./backoffice/pages/ViewItemJournal/ViewItemJournal";
import PaymentResp from "./backoffice/pages/PaymentRespStore/PaymentResp";
import CheckHomeWork from "./backoffice/pages/CheckWork/CheckHomeWork";
import StudentCard from "./backoffice/pages/InfoStudents/StudentCard/StudentCard";
import CheckTest from "./backoffice/pages/CheckTest/CheckTest";
import CheckCode from "./backoffice/pages/CheckCodeWork/CheckCode";
import HistoryPay from "./backoffice/pages/HistoryPay/HistoryPay";
import Modules from "./backoffice/pages/Modules/Modules";
import PaymentModule from "./backoffice/pages/PaymentModuleResp/PaymentModule";
import AuthToken from "./backoffice/pages/AuthToken/AuthToken";
import LandingNew from "./backoffice/pages/LandingNew/LandingNew";
import SearchProfession from "./backoffice/pages/SearchProfession/SearchProfession";
import InfoStudent from "./backoffice/pages/InfoStudents/InfoStudents";
import CheckTestScorm from "./backoffice/pages/CheckTest/CheckTestScorm";
import Layout from "./backoffice/components/Layout/Layout";
import GenerateCourse from "./backoffice/pages/GenerateCourse/GenerateCourse";
import SkillSetContainer from "./backoffice/pages/SkillSet/SkillSetContainer";
import SearchProfessionSkillSet from "./backoffice/pages/SearchProfession/SearchProfessionSkillSet";
import GenerateCourseSkillSet from "./backoffice/pages/GenerateCourse/GenerateCourseSkillSet";

const PrivateRoute = () => {
    if (!localStorage.getItem("token") && !window.location.href.includes("authorization-link")) {
        return (
            <Navigate to={{pathname: "/backoffice/login"}}/>
        )
    } else
        return (
            <Layout>
                <React.Suspense
                    fallback={<div
                        style={{position: "absolute", top: "2%", left: "50%", transform: "translateX(-50%)"}}
                    >
                        Loading...
                    </div>}
                >
                    <Outlet/>
                </React.Suspense>
            </Layout>
        )
};
const App = () => {
    let location = useLocation();
    const dispatch = useDispatch();

    let getApiNotification = () => {
        requests.notification.get_count_notification()
            .then((res) => {
                if (typeof res.data.count !== "undefined")
                    localStorage.setItem("count_notification", res.data.count);
            })
            .catch(err => console.error(err));
    };
    let getModules = () => {
        requests.organization.get_modules_list()
            .then((res) => {
                const data = res.data;
                let activeModules = [];
                if (data?.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].current_subscriptions.length > 0) {
                            activeModules = [...activeModules, data[i].type]
                        }
                    }
                }
                dispatch(setActiveModules(activeModules))
                dispatch(setListModules(data))
            })
            .catch(err => console.error(err))
    }
    let getApiTrajectorySkill = () => {
        requests.auth.get_organization_info()
            .then(res => {
                dispatch(setUserTrajectory(res.data?.planning_skills || []))
            })
    };
    let getApiUserTags = () => {
        let arrSkills = [];
        requests.quests.get_quest_pagination("", "", 1, 500)
            .then(res => {
                if (res.data) {
                    let data = res.data?.results;
                    for (let i = 0; i < data.length; i++) {
                        for (let j = 0; j < data[i].speciality_tag.length; j++) {
                            arrSkills = [...arrSkills, data[i].speciality_tag[j]]
                        }
                    }
                    let tmpArray = [];
                    let skillCheck = (item) => {
                        if (tmpArray.indexOf(item.id) === -1) {
                            tmpArray.push(item.id);
                            return true
                        }
                        return false;
                    };
                    arrSkills = arrSkills.filter(skill => skillCheck(skill))
                    dispatch(setUserSkills(arrSkills))
                }
            })
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            localStorage.removeItem("count_notification");
            getModules();
            getApiNotification();
            setInterval(() => {
                getApiNotification();
            }, 30000);
        }
        if (localStorage.getItem("language")) {
            dispatch(changeLanguage(localStorage.getItem("language")));
        } else dispatch(changeLanguage("ru"));
    }, []);
    useEffect(() => {
        if (localStorage.getItem("token")) {
            getModules();
            getApiTrajectorySkill();
            getApiUserTags();
        }
        requests.auth.get_profile_new()
            .then(res => {
                if (res.data?.organization?.status === 0) {
                    dispatch(setEndTrialDate(res.data.organization.trial_end_date));
                    dispatch(setStatusTrial(0));
                }
                if (res.data?.organization?.status === 1) {
                    if (res.data?.organization?.trial_end_date) {
                        dispatch(setEndTrialDate(res.data.organization.trial_end_date));
                    }
                    dispatch(setStatusTrial(1));
                }
                if (res.data?.organization?.status === 2) {
                    dispatch(setEndTrialDate(res.data.organization.trial_end_date));
                    dispatch(setStatusTrial(2));
                }
                dispatch(setUser(res.data))
            });
    }, [location]);
    return (
        <div
            className={cn("app", {
                    "overflow_hidden": window.location.href.includes("/academic-plan")
                }
            )}
        >
                <Routes>
                    <Route path={"/backoffice/login"} element={<Login />}/>
                    <Route path={"/backoffice/register"} element={<Register />}/>
                    <Route path={"/backoffice/forgot_password"} element={<ForgotPassword />}/>
                    <Route path={"/generate-course"} element={<GenerateCourse/>}/>
                    <Route path={"/"} element={<PrivateRoute/>}>
                        <Route path={"/"} element={<MyCourse/>}/>
                        <Route path={"/backoffice/my-course"} element={<MyCourse/>}/>
                        <Route path={"/backoffice/skill-set"} element={<SkillSetContainer/>}>
                            <Route path={"/backoffice/skill-set/prof-review"} element={<SearchProfessionSkillSet/>}/>
                            <Route path={"/backoffice/skill-set/generate-course"} element={<GenerateCourseSkillSet/>}/>
                            <Route path={"/backoffice/skill-set/courses"} element={<MyCourse/>}/>
                        </Route>
                        <Route path={"/backoffice/academic-plan"} element={<SearchProfession/>}/>
                        <Route path={"/backoffice/profile"} element={<Profile/>}/>
                        <Route path={"/notification"} element={<Notification/>}/>
                        <Route path={"/backoffice/history-pay"} element={<HistoryPay/>}/>
                        <Route path={"/backoffice/request-members"} element={<InnerRequestMember/>}/>
                        <Route path={"/backoffice/groups-list"} element={<GroupsList/>}/>
                        <Route path={"/backoffice/student-card/:id"} element={<StudentCard/>}/>
                        <Route path={"/backoffice/student-progress/:id/:currentTab"} element={<InfoStudent/>}/>
                        <Route path={"/backoffice/view-course/:id"} element={<CourseManageNew/>}/>
                        <Route path={"/backoffice/current-course/:quest_id"} element={<AddCourseNew/>}/>
                        <Route path={"/backoffice/current-course/:module"} element={<AddCourseNew/>}/>
                        <Route path={"/backoffice/current-course/"} element={<AddCourseNew/>}/>
                        <Route path={"/backoffice/view-item-journal/:id"} element={<ViewItemJournal/>}/>
                        <Route path={"/backoffice/payment/:status/:id"} element={<PaymentResp/>}/>
                        <Route path={"student/payment/education/:status/:id"} element={<PaymentResp/>}/>
                        <Route path={"/backoffice/payment/module/:status/:id"} element={<PaymentModule/>}/>
                        <Route path={"/backoffice/check-test/:quizId/:userId"} element={<CheckTest/>}/>
                        <Route path={"/backoffice/check-test-scorm/:quizId/:userId"} element={<CheckTestScorm/>}/>
                        <Route path={"/backoffice/modules"} element={<Modules/>}/>
                        <Route path={"/backoffice/course-preview/:id"} element={<LandingNew/>}/>
                        <Route path={"/backoffice/authorization-link/:token"} element={<AuthToken/>}/>
                        <Route path={"/backoffice/generate-course"} element={<GenerateCourse/>}/>
                        <Route
                            path={"/backoffice/check-homework/:id/:taskId/:taskStageId/:readOnly"}
                            element={<CheckHomeWork/>}
                        />
                        <Route
                            path={"/backoffice/check-codework/:id/:taskId/:taskStageId/:readOnly"}
                            element={<CheckCode/>}
                        />
                    </Route>
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Routes>
        </div>
    );
};

export default App;
