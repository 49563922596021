import axios from "axios";
import { message } from "antd";

//const baseURLNotification = 'https://notify.skillometer.ru/api/';
const defaultSettingsNeyron = {
    //  baseURL: process.env.REACT_APP_API_URL_NEYRON
    baseURL: "https://skillometer.ru/",
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    }
};
const baseURLNotification = process.env.REACT_APP_API_URL_NOTIFICATION;
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,//"https://skillometer.ru/api" ||
});

apiClient.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem("token");
        if (token) {
            config.headers.common.Authorization = `Token ${token}`;
        }
        return config;
    },
    null, {
        synchronous: true
    }
);

const responseSuccessHandler = response => {
    return response;
};
const responseErrorHandler = error => {
    const errorStatus = error?.response?.status || 0;
    const currentUrl = window.location.href;

    try {
        if (errorStatus === 401) {
            if (!currentUrl.includes("login")
                && !currentUrl.includes("register")
                && !currentUrl.includes("forgot_password")
                && !currentUrl.includes("authorization-link")
                && !currentUrl.includes("generate-course")
            ) {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("user_id");
                window.localStorage.removeItem("name");
                window.location.reload(false);
                return window.location.replace("/backoffice/login");
            }
        }
        if (errorStatus === 403) {
        }
        if (errorStatus === 400 && currentUrl.includes("/backoffice/profile")) {
            if (
                localStorage.getItem("language") === "en"
                && error.response?.data?.error.includes("Банк не смог найти указанный адрес")
            ) {
                message.error("The bank could not find the specified address. Check the completed data.", 8);
            } else
                message.error(error.response?.data?.error, 8);

            return Promise.reject(error);
        }
        if (errorStatus === 500 &&
            (currentUrl.includes("check-codework") || currentUrl.includes("check-homework") || currentUrl.includes("check-test"))
        ) {
            message.error("Это задание было удалено.")
        }
    } catch (err) {
        console.error(err);
    }

    return Promise.reject(error);
};

apiClient.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
);
export default {
    auth: {
        login(request) {
            return apiClient.post(`backoffice/user_login`, request); // +++ /api/backoffice/user_login
        },
        register(request) {
            return apiClient.post(`backoffice/user_register`, request); // +++ /api/backoffice/user_register
        },
        get_profile_new() {
            return apiClient.get(`/v2/web/student/profile/profile/`);
        },
        edit_profile_new(request) {
            return apiClient.patch(`/v2/web/student/profile/profile/`, request);
        },
        post_organization_info(request) {
            return apiClient.post(`backoffice/organization_cr_upd_id`, request); // +++ /api/backoffice/post_organization_info {MANY}
        },
        put_organization_info(request) {
            return apiClient.put(`backoffice/organization_cr_upd_id`, request); // +++ /api/backoffice/post_organization_info {MANY}
        },
        patch_organization_info(request) {
            return apiClient.patch(`backoffice/organization_cr_upd_id`, request); // +++ /api/backoffice/post_organization_info {MANY}
        },
        get_organization_info() {
            return apiClient.get(`backoffice/organization_cr_upd_id`);
        },
        logout() {
            return apiClient.post(`backoffice/user_logout`);
        },

        password_forgotten(password) {
            return apiClient.post(`backoffice/password_forgotten`, password);
        },
        password_restore(password) {
            return apiClient.post(`backoffice/password_restore`, password);
        },
        education_plan(request) {
            return apiClient.post("/v2/web/user/education_plan/", request)
        },
        fetch_education_plan(id) {
            return apiClient.get(`/v2/web/user/education_plan/?speciality_tag=${id}`)
        },
        generate_quest(id) {
            return apiClient.post(`/v2/web/user/education_plan/${id}/generate_quest/`)
        },
        get_config() {
            return apiClient.get("/v2/web/common/config/")
        },
        calery_progress(id) {
            return apiClient.get(`/celery-progress/${id}/`)
        },
        buy_education_plan(tries) {
            return apiClient.post("/v2/web/user/education_plan/payment/init/", {tries})
        },
        get_plannings() {
            return apiClient.get("/v2/web/user/education_plan/",
                {params: {page_size: 1000}}
            )
        }
    },
    users: {
        get_profile_new() {
            return apiClient.get(`/v2/web/student/profile/profile/`);
        },
        logout() {
            return apiClient.post(`request/logout`);
        },
        education_plan(request) {
            return apiClient.post("/v2/web/user/education_plan/", request)
        },
        generate_quest(id) {
            return apiClient.post(`/v2/web/user/education_plan/${id}/generate_quest/`)
        },
        fetch_education_plan(id) {
            return apiClient.get(`/v2/web/user/education_plan/?speciality_tag=${id}`)
        },
        get_config() {
            return apiClient.get("/v2/web/common/config/")
        },
        calery_progress(id) {
            return apiClient.get(`/celery-progress/${id}/`)
        },
        buy_education_plan(tries) {
            return apiClient.post("/v2/web/user/education_plan/payment/init/", {tries})
        },
        get_plannings() {
            return apiClient.get("/v2/web/user/education_plan/",
                {params: {page_size: 1000}}
            )
        },
        changeFavoritePlan(id, value) {
            return apiClient.patch(`/v2/web/user/education_plan/${id}/`, {favorite: value})
        },
        fetchFavorites(page, page_size, speciality_tag) {
            return apiClient.get("/v2/web/user/education_plan/?favorite=true", {
                    params: {
                        page,
                        page_size,
                        ...(speciality_tag ? {speciality_tag} : {})
                    }
                }
            )
        }
    },
    storage: {
        get_yandex_key(request) {
            return apiClient.post(`backoffice/get_yandex_key`, request);
        },
        get_yandex_quest_key() {
            return apiClient.get(`backoffice/get_yandex_quest_key`);
        },
        get_yandex_task_key() {
            return apiClient.get(`backoffice/get_yandex_task_key?sent_by=teacher`);
        },
        storage_upload(yandexKey, file, progressFunc, fileTypes) {
            let contentType = "application/octet-stream";
            if (fileTypes === "video/*") {
                contentType = "video/mp4";
            }

            function encodeRFC5987ValueChars(str) {
                return encodeURIComponent(str).
                    // Замечание: хотя RFC3986 резервирует "!", RFC5987 это не делает, так что нам не нужно избегать этого
                    replace(/['()]/g, escape). // i.e., %27 %28 %29
                    replace(/\*/g, "%2A").
                    // Следующее не требуется для кодирования процентов для RFC5987, так что мы можем разрешить немного больше читаемости через провод: |`^
                    replace(/%(?:7C|60|5E)/g, unescape);
            }

            let formData = new FormData();
            [
                // ["Content-Type", "application/octet-stream"],
                ["Content-Type", contentType],
                ["Content-Disposition", `attachment; filename*=UTF-8''${encodeRFC5987ValueChars(file.name)}`],
                ["key", yandexKey],
                ["file", file]
            ].forEach(([k, v]) => formData.append(k, v));

            return apiClient.request({
                url: "https://storage.yandexcloud.net/skill-storage/",
                // url: "https://storage.yandexcloud.net/skill-storage-test/",
                method: "POST",
                data: formData,
                onUploadProgress: progressFunc,
                /*    headers: {'Authorization': 'Token ' + 'YCNYYW0LPGynBqFf2zz6wJugmdBhCsMIkT1zrxDL'}*/
            });
            // fetch(`https://storage.yandexcloud.net/skill-storage/`, postConfig)
        }
    },
    quests: {
        get_org_quests() {
            return apiClient.get(`backoffice/quest_list_cr`); // +++ /api/backoffice/get_org_quests {"user_id": "6"}
        },
        add_quest_new(request) {
            return apiClient.post(`/v2/web/teacher/quest/quest/`, request);
        },
        get_quest_new(request) {
            if (request) {
                return apiClient.get(`/v2/web/teacher/quest/quest/${request}/`);
            } else
                return apiClient.get(`/v2/web/teacher/quest/quest/`);
        },
        get_quest_pagination(category, words, currentPage, count) {
            let str = "";
            if (category !== 0) str = str + `speciality=${category}`;
            if (words) str = str + `&q=${words}`;
            str = str + `&page=${currentPage}&page_size=${count}`;
            return apiClient.get(`/v2/web/teacher/quest/quest/?` + str);
        },
        edit_quest_new(quest_id, request) {
            return apiClient.patch(`/v2/web/teacher/quest/quest/${quest_id}/`, request);
        },
        get_quest_groups(id, word, currentPage = null, count = null, archive = false) {
            let str = "";
            if (id) str += `&quest=${id}`;
            if (word) str += `&q=${word}`;
            if (currentPage) str += `&page=${currentPage}`;
            if (count) str += `&page_size=${count}`;
            if (archive) str += `&archived=${archive}`;
            if (str) {
                str = str.replace('&', '');
                str = '?' + str;
            }
            return apiClient.get(`/v2/web/teacher/quest/quest_group/` + str);
        },
        upload_image_course(formData, progressFunc) {
            return apiClient.request({
                url: "/v2/web/common/upload_image/",
                method: "POST",
                data: formData,
                onUploadProgress: progressFunc,
            });
        },
        create_quest_groups(request) {
            return apiClient.post("/v2/web/teacher/quest/quest_group/", request);
        },
        change_quest_group(group_id, request) {
            return apiClient.patch(`/v2/web/teacher/quest/quest_group/${group_id}/`, request);
        },
        get_quest_speakers(request) {
            return apiClient.get("/v2/web/teacher/quest/quest_speaker/?quest=" + request);
        },
        create_quest_speaker(request) {
            return apiClient.post("/v2/web/teacher/quest/quest_speaker/", request);
        },
        change_quest_speaker(speaker_id, request) {
            return apiClient.patch(`/v2/web/teacher/quest/quest_speaker/${speaker_id}/`, request);
        },
        get_quest_suits(request) {
            return apiClient.get(`/v2/web/teacher/quest/quest_recommendation/?quest=${request}`);
        },
        create_quest_suits(request) {
            return apiClient.post("/v2/web/teacher/quest/quest_recommendation/", request);
        },
        change_quest_suits(suits_id, request) {
            return apiClient.patch(`/v2/web/teacher/quest/quest_recommendation/${suits_id}/`, request);
        },
        get_quest_learn(request) {
            return apiClient.get(`/v2/web/teacher/quest/quest_skill/?quest=${request}`);
        },
        create_quest_learn(request) {
            return apiClient.post("/v2/web/teacher/quest/quest_skill/", request);
        },
        change_quest_learn(suits_id, request) {
            return apiClient.patch(`/v2/web/teacher/quest/quest_skill/${suits_id}/`, request);
        },
        remove_quest_learn(id) {
            return apiClient.delete(`/v2/web/teacher/quest/quest_skill/${id}/`);
        },
        remove_quest_group(id) {
            return apiClient.delete(`/v2/web/teacher/quest/quest_group/${id}/`);
        },
        remove_quest_speaker(id) {
            return apiClient.delete(`/v2/web/teacher/quest/quest_speaker/${id}/`);
        },
        remove_quest_suits(id) {
            return apiClient.delete(`/v2/web/teacher/quest/quest_recommendation/${id}/`);
        },
        quest_is_enabled(request) {
            return apiClient.post(`backoffice/quest_is_enabled`, request); // +++ /api/backoffice/quest_is_enabled {quest_id: 98, is_enabled: true}
        },
        get_student_progress(member_id) {
            return apiClient.get(`/v2/web/teacher/quest/quest_member/${member_id}/progress/`); // +++ /api/backoffice/get_incoming_requests {"user_id": "6"}
        },
        get_incoming_requests_new(request) {
            return apiClient.get(`/v2/web/teacher/quest/quest_member/` + request); // +++ /api/backoffice/get_incoming_requests {"user_id": "6"}
        },
        get_progress_by_quest_new(group_id, ordering, words) {
            let str = "";
            if (ordering) {
                if (ordering === 1)
                    str = `&ordering=-progress`;
                if (ordering === 2)
                    str = `&ordering=user__profile__last_name`;
            }
            if (words) str = str + `&q=${words}`;
            return apiClient.get(`/v2/web/teacher/quest/quest_member/?quest_group=${group_id}` + str);
        },
        change_incoming_member(member_id, request) {
            return apiClient.patch(`/v2/web/teacher/quest/quest_member/${member_id}/`, request);
        },
        delete_quest(quest_id) {
            return apiClient.delete(`backoffice/quest_upd_del_id/${quest_id}`);
        },
        make_a_link(quest_id) {
            return apiClient.get(`backoffice/make_a_link?quest_id=${quest_id}`);
        },
        homework_checked_list_new(group_id, words, status, task) {
            let str = "";
            if (words)
                str = `&q=${words}`;
            if (status !== null && typeof status !== "undefined")
                str = str + `&check_status=${status}`;
            if (task)
                str = str + `&task=${task}`;
            return apiClient.get(`/v2/web/teacher/task_answer/task_answer/?quest_group=${group_id}` + str);
        },
        homework_check(request) {
            return apiClient.post(`backoffice/homework_check`, request);
        },
        homework_history(task_id) {
            return apiClient.get(`backoffice/homework_history?task_id=${task_id}`);
        },
        get_homework_history_new(task_id) {
            return apiClient.get(`/v2/web/teacher/task_answer/task_answer/${task_id}/`)
        },
        get_stats(period, quest_id) {
            let str = "";
            if (quest_id) str = `&quest=${quest_id}`;
            return apiClient.get(`/v2/web/teacher/quest/progress/?period=${period}` + str);
        },
        get_stats_progress(group_id) {
            return apiClient.get(`/v2/web/teacher/quest/quest_member/total/?quest_group=${group_id}`);
        },
        post_code_editor(request) {
            return apiClient.request(
                {
                    method: "post",
                    url: "http://178.154.252.78:3000/",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: request
                }
            );
        },
        get_quest_quest(arr, currentPage, count, speciality, my = "", isMyCourse = false, page_size = "20") {
            let finished = '&finished=false';
            if (isMyCourse)
                finished = '';
            return apiClient.get(`/v2/web/student/quest/quest/?speciality_tag=${arr || []}&speciality=${speciality || ""}&page=${currentPage}&page_size=${page_size}&my=${my || "none"}` + finished);
        },
        get_quests() {
            return apiClient.get(`request/get_quests`);
        },
        fetchGptApi(request) {
            return apiClient.post("/v2/web/teacher/quest/quest/generate_description/", request)
        },
        studentsFile(id) {
            return apiClient.post(`/v2/web/teacher/quest/quest_group/${id}/statistic/`, {})
        }
    },
    stages: {
        get_stages_quest(quest_id, words) {
            let str = "";
            if (words) str = `&q=${words}`;
            return apiClient.get(`/v2/web/teacher/stage/stage/?quest=${quest_id}` + str); // +++ /api/backoffice/get_stages {"user_id": "675", "quest_id": "147"}
        },
        get_stages_matherial(stage_id) {
            return apiClient.get(`/v2/web/teacher/stage/stage_theory/?stage=${stage_id}`); // +++ /api/backoffice/get_stages {"user_id": "675", "quest_id": "147"}
        },
        create_stages_metherial(request) {
            return apiClient.post(`/v2/web/teacher/stage/stage_theory/`, request); // +++ /api/backoffice/get_stages {"user_id": "675", "quest_id": "147"}
        },
        scorm_api(file, id, stage_theory, progressFunc,) {
            var formData = new FormData();
            [
                ["Content-Type", "multipart/form-data"],
                ["file", file],
            ].forEach(([k, v]) => formData.append(k, v));

            return apiClient.request({
                url: `v2/web/teacher/stage/stage_theory/${stage_theory}/lms_package/`,
                baseURL: process.env.REACT_APP_API_URL,
                method: "PUT",
                data: formData,
                onUploadProgress: progressFunc,
            });
        },
        get_upload_progress_lms(id) {
            return apiClient.get(`/celery-progress/${id}/`)
        },
        change_stages_matherial(stage_id, request) {
            return apiClient.patch(`v2/web/teacher/stage/stage_theory/${stage_id}/`, request); // +++ /api/backoffice/get_stages {"user_id": "675", "quest_id": "147"}
        },
        change_stages_quest(id, request) {
            return apiClient.patch(`/v2/web/teacher/stage/stage/${id}/`, request); // +++ /api/backoffice/get_stages {"user_id": "675", "quest_id": "147"}
        },
        get_stage_theories_info(stage_id) {
            return apiClient.get(`backoffice/theory_list_cr?stage_id=${stage_id}`);
        },
        stage_add(request) {
            return apiClient.post(`backoffice/stage_list_cr`, request); // +++ /api/backoffice/stage_add BUT 405 METHOD NOT ALLOWED {name: "5", post: "Another stqage", quest_id: "147"}
        },
        stage_edit(id, request) {
            return apiClient.put(`backoffice/stage_upd_del_id/${id}`, request);
        },
        delete_stage(id) {
            return apiClient.delete(`backoffice/stage_upd_del_id/${id}`);
        },
    },
    quiz: {
        create_question(request) {
            return apiClient.post("/v2/web/teacher/quiz/quiz_question/", request);
        },
        create_answers(request) {
            return apiClient.post("/v2/web/teacher/quiz/quiz_answer/", request);
        },
        create_recomendations(request) {
            return apiClient.post("/v2/web/teacher/quiz/quiz_response/", request);
        },
        change_question(id, request) {
            return apiClient.patch(`/v2/web/teacher/quiz/quiz_question/${id}/`, request);
        },
        change_answers(id, request) {
            return apiClient.patch(`/v2/web/teacher/quiz/quiz_answer/${id}/`, request);
        },
        change_recomendations(id, request) {
            return apiClient.patch(`/v2/web/teacher/quiz/quiz_response/${id}/`, request);
        },
        remove_answers(id) {
            return apiClient.delete(`/v2/web/teacher/quiz/quiz_answer/${id}/`);
        },
        remove_questions(id) {
            return apiClient.delete(`/v2/web/teacher/quiz/quiz_question/${id}/`);
        },
        remove_recomendations(id) {
            return apiClient.delete(`/v2/web/teacher/quiz/quiz_response/${id}/`);
        },
        get_quiz_result(quiz_id, words, last, group_id) {
            let str = "";
            if (quiz_id) str = str + `&quiz=${quiz_id}`;
            if (words) str = str + `&q=${words}`;
            if (group_id) str = str + `&quest_group=${group_id}`;
            if (last) str = str + `&last=true`;
            if (str) {
                str = `?${str.slice(1)}`;
            }
            return apiClient.get(`/v2/web/teacher/quiz/quiz_result/` + str);
        },
        get_quiz_result_id(request) {
            return apiClient.get(`/v2/web/teacher/quiz/quiz_with_results/${request.quizId}/?user=${request.userId}`);
        },
    },
    theories: {
        delete_stage_matherial(theory_id) {
            return apiClient.delete(`backoffice/theory_upd_del/${theory_id}`);
        },
        theory_edit(theory_id, request) {
            return apiClient.put(`backoffice/theory_upd_del/${theory_id}`, request);
        },
    },
    comments: {
        get_comments(request) {
            let str = `?content_type=${request.contentType}&object_id=${request.objectId}`;
            return apiClient.get("/v2/web/student/reaction/comment/" + str);
        },
        create_comment(request) {
            return apiClient.post("/v2/web/student/reaction/comment/", request);
        },
        remove_comment(comment_id) {
            return apiClient.delete(`/v2/web/student/reaction/comment/${comment_id}/`);
        },
        like_dislike(request) {
            return apiClient.post("/v2/web/student/reaction/like/", request);
        },
        get_quest_review(id) {
            return apiClient.get(`/v2/web/student/quest/quest_review/?quest=${id}`);
        },
    },
    notification: {
        get_notification(request) {
            let strViewed = "";
            if (request.viewed !== null)
                strViewed = `&viewed=${request.viewed}`;
            return apiClient.get(`notification/?page=${request.page}&size=${request.size}` + strViewed, {baseURL: baseURLNotification});
        },
        post_view_notification(id) {
            return apiClient.post(`notification/${id}/view/`, {}, {baseURL: baseURLNotification});
        },
        get_count_notification() {
            return apiClient.get("notification/count/", {baseURL: baseURLNotification});
        }
    },
    organization: {
        organization_create_store(request) {
            return apiClient.post("/v2/web/teacher/organization/store/", request);
        },
        organization_patch_store(request) {
            return apiClient.patch("/v2/web/teacher/organization/store/", request);
        },
        oraganization_create_adress(requst) {
            return apiClient.post("/v2/web/teacher/organization/store_address/", requst);
        },
        oraganization_patch_adress(id, request) {
            return apiClient.patch(`/v2/web/teacher/organization/store_address/${id}/`, request);
        },
        oraganization_send_store() {
            return apiClient.post("/v2/web/teacher/organization/send_store/");
        },
        get_organization_store() {
            return apiClient.get("/v2/web/teacher/organization/store/");
        },
        get_organization_adress() {
            return apiClient.get("/v2/web/teacher/organization/store_address/");
        },
        create_payment_init() {
            return apiClient.post(`/v2/web/teacher/organization/payment/init/`);
        },
        get_payment_state(id) {
            return apiClient.get(`/v2/web/teacher/organization/payment/get_state/${id}/`);
        },
        get_modules_list() {
            return apiClient.get('/v2/web/teacher/organization/module/')
        },
        create_link_payment_module(id) {
            return apiClient.post(`/v2/web/teacher/organization/module/${id}/payment/init/`)
        },
        get_payment_status_module(id) {
            return apiClient.get(`/v2/web/teacher/organization/module/payment/get_state/${id}/`)
        },
        get_payment_history(period, sort, courseName, currentPage, countOnPage) {
            let str = `?ordering=${sort}&page=${currentPage}&page_size=${countOnPage}`;
            if (period !== 0 && period !== "all") str += `&created_at=${period}`;
            if (courseName) str += `&q=${courseName}`;

            return apiClient.get('/v2/web/teacher/quest/payment/' + str);
        },
        get_payment_progress(request) {
            return apiClient.get(`/v2/web/teacher/quest/payment/progress/?period=${request}`)
        },
        has_base_acccess() {
            return apiClient.get("/v2/web/teacher/organization/module/has_base_access/")
        }
    },
    profs: {
        get_industry() {
            return apiClient.get(`v2/web/student/vacancy/hh/industry/`);
        },
        get_profs_neyron(current, tags) {
            return apiClient.post(`v2/web/student/profession/profession/${current}/`, tags);
        },
        get_hh_vacancy_filter({
                                  text,
                                  area,
                                  search_field,
                                  industry,
                                  salary,
                                  only_with_salary,
                                  experiense,
                                  schedule,
                                  order_by,
                                  period
                              }) {
            let params = {
                ...(text ? {text} : {}),
                ...(area ? {area} : {}),
                ...(industry ? {industry} : {}),
                ...(salary ? {salary} : {}),
                ...(only_with_salary ? {only_with_salary} : {}),
                ...(experiense ? {experiense} : {}),
                ...(schedule ? {schedule} : {}),
                ...(order_by ? {order_by} : {}),
                ...(period ? {period} : {}),
                ...(search_field
                        ? {search_field: `name,${search_field}`}
                        : {search_field: `name`}
                )
            }
            return apiClient.get(`v2/web/student/vacancy/hh/vacancies/`, {params});
        },
        get_hh_vacancy(professionName, region) {
            return apiClient.get(`v2/web/student/vacancy/hh/vacancies/?text=${professionName}&area=${region || ""}`);
        },
        get_trudvsem_vacancy(professionName, region, disability) {
            return apiClient.get(`/v2/web/student/vacancy/trud_vsem/vacancies/?text=${professionName}&area=${region}&limited=${disability}`);
        },
        get_profession_favorite() {
            return apiClient.get("/v2/web/student/profession/favorite_profession/");
        },
        get_vacancy_favorite() {
            return apiClient.get("/v2/web/student/vacancy/favorite_vacancy/");
        },
        add_vacancy_favorite(request) {
            return apiClient.post("/v2/web/student/vacancy/favorite_vacancy/", request);
        },
        remove_vacancy_favorite(request) {
            return apiClient.delete(`/v2/web/student/vacancy/favorite_vacancy/${request}/`);
        },
        add_profession_favorite(request) {
            return apiClient.post("/v2/web/student/profession/favorite_profession/", request);
        },
        remove_profession_favorite(request) {
            return apiClient.delete(`/v2/web/student/profession/favorite_profession/${request}/`);
        },
        profs_list(request) {
            let str = "";
            if (request) str = `?type=it`;
            return apiClient.get(`/v2/web/student/profession/profs_list/` + str);
        },
        get_speciality_tags(name, page, page_size) {
            let params = {name, page, page_size};
            return apiClient.get(`/v2/web/common/speciality_tag/`, {params});
        },
        get_headHunter_jobs(jobName, city) {
            return apiClient.get(`request/get_headhunter_vacancies?text=${jobName}&area=${city}`);
        },
    },
    voice_gen: {// base: https://skillometer.ru/api/
        synthesize_retrieve(payload) {
            return apiClient.get('/v2/teacher/stage/synthesize/',{params: payload})
        },
        synthesize_list(payload) {
            return apiClient.get('/v2/teacher/stage/synthesize/',{params: payload})
        },
        synthesize_create(payload) {
            return apiClient.post('/v2/teacher/stage/synthesize/',payload)
        },
    }
};