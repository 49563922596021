import React, { useEffect, useRef, useState } from "react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import requests from "../../axios/requests";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import { useSelector } from "react-redux";

import {message, Popconfirm, Tabs, Tooltip} from "antd";
import Step1 from "./steps/Step_1";
import Step2 from "./steps/Step_2";
import Step3 from "./steps/Step_3";
import Trial from "../../components/reuseComponents/Trial";

import { ReactComponent as RemoveCourse1 } from "../../../images/icons/remove_course1.svg";
import { ReactComponent as RemoveCourse2 } from "../../../images/icons/remove_course2.svg";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { defaultFuncAndData } from "../../utils/defaultData";

const CurrentCourse = (props) => {
    const history = useNavigate();
    const { pathname } = useLocation();
    const params = useParams();
    const quest_id_ses = params.quest_id;
    const stepsRef = useRef();
    const {activeModules, trial_status, trajectorySkills} = useSelector(state => state.Reducer);

    const variable_1 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "currentCourse_step_1", keyVariable: "variable_10"});
    const variable__3 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_3"});
    const variable_19 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_20"});

    const [courseData, setCourseData] = useState({
        "id": null,
        "ses": "",
        "name": "",
        "description": "",
        "dateBegin": "",
        "dateEnd": "",
        "y_image": "",
        "y_image_name": "",
        "phone": "",
        "participatetype": "",
        "participateprice": 0,
        "payment_redirect": false,
        "academicHours": 1,
        "isCorporate": false,
        "demo_video": "",
        "demo_video_name": "",
        "demo_youtube": "",
        "email": "",
        "isEnabled": false,
        "speciality": 4,
        "speciality_tag": [],
        "groups": null,
        "speakers": null,
        "suits": null,
        "learn": null,
        "banner": null,
        "banner_name": null,
        "banner_position": "left",
        "content_description": null,
        "access_after_begin": false,
        "show_comments": true
    });
    const [responseDataId, setResponseDataId] = useState(null);
    const [courseDataMatherial, setCourseDataMatherial] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);

    const [arrForRemoveLearn, setArrForRemoveLearn] = useState([]);
    const [arrForRemoveGroup, setArrForRemoveGroup] = useState([]);
    const [arrRemoveSpeaker, setArrRemoveSpeaker] = useState([]);
    const [arrRemoveSuits, setArrRemoveSuits] = useState([]);

    const [yandexKeyCardImage, setYandexKeyCardImage] = useState("");
    const [yandexKeyBannerImage, setYandexKeyBannerImage] = useState("");
    const [yandexKeyVideoPreview, setYandexKeyVideoPreview] = useState("");

    const [activeTab, setActiveTab] = useState("current-course");

    let deleteCourse = () => {
        if (!courseData.id && !responseDataId?.id) return history(`/backoffice/my-course`);
        requests.quests.delete_quest(courseData.id || responseDataId.id).then((res) => {
            if (res.status === 204) {
                message.success(variable_6);
            }
            history(`/backoffice/my-course`);
            return res;
        });
    };
    let convertDate = (str) => {
        let newStr = "";
        if (str) {
            if (responseDataId && str.includes("-")) return str;
            let conv = str.includes("-") ? str.split("-") : str.split(".");
            newStr = `${conv[2]}-${conv[1]}-${conv[0]}`;
        }
        return newStr;
    };

    // эти api (спикеров, групп..) используется при первом создании курса.
    let apiCreateSpeaker = () => {
        let newArr = [];
        if (courseData.speakers && courseData.speakers.length > 0) {
            for (let i = 0; i < courseData.speakers.length; i++) {
                let obj = {
                    quest: responseDataId.id,
                    first_name: courseData.speakers[i].first_name,
                    last_name: courseData.speakers[i].last_name,
                    position: courseData.speakers[i].position,
                    description: courseData.speakers[i].description,
                    avatar: courseData.speakers[i].avatar,
                };
                newArr = [...newArr, obj];
            }
            requests.quests.create_quest_speaker(newArr)
                .then((res) => {
                    setCourseData((prev) => ({...prev, ["speakers"]: res.data}));
                })
                .catch((err) => console.log(err, "err create_quest_speaker"));
        }
    };
    let apiCreateGroup = () => {
        if (courseData.groups && courseData.groups.length > 0) {
            let newArr = [];
            for (let i = 0; i < courseData.groups.length; i++) {
                let obj = {
                    quest: responseDataId.id,
                    name: courseData.groups[i].name,
                };
                newArr = [...newArr, obj];
            }
            requests.quests.create_quest_groups(newArr)
                .then((res) => {
                    setCourseData((prev) => ({...prev, ["groups"]: res.data}));
                })
                .catch((err) => console.log(err, "err create_quest_groups"));
        }
    };
    let apiCreateSuits = () => {
        let newArr = [];
        if (courseData.suits && courseData.suits.length > 0) {
            for (let i = 0; i < courseData.suits.length; i++) {
                let obj = {
                    quest: responseDataId.id,
                    title: courseData.suits[i].title,
                    description: courseData.suits[i].description,
                };
                newArr = [...newArr, obj];
            }
            requests.quests.create_quest_suits(newArr)
                .then((res) => {
                    requests.quests.get_quest_suits(responseDataId.id)
                        .then((res) => {
                            setCourseData((prev) => ({...prev, ["suits"]: res.data}));
                        })
                        .catch((err) => console.log(err, "err get_quest_suits"));
                })
                .catch((err) => console.log(err, "err create_quest_suits"));
        }
    };
    let apiCreateLearn = () => {
        let newArr = [];
        if (courseData.learn && courseData.learn.length > 0) {
            for (let i = 0; i < courseData.learn.length; i++) {
                let obj = {
                    quest: responseDataId.id,
                    speciality_tag: courseData.learn[i].speciality_tag.id,
                    description: courseData.learn[i].description,
                };
                newArr = [...newArr, obj];
            }
            requests.quests.create_quest_learn(newArr)
                .then((res) => {
                    requests.quests.get_quest_learn(responseDataId.id)
                        .then((res) => {
                            setCourseData((prev) => ({...prev, ["learn"]: res.data}));
                        })
                        .catch((err) => console.log(err, "err get_quest_learn"));
                })
                .catch((err) => console.log(err, "err create_quest_learn"));
        }
    };

    // эти api (спикеров, групп..) используются при редактировании уже существующего курса.
    let createSpeaker = (arr) => {
        if (courseData.id) {
            for (let i = 0; i < arr.length; i++) {
                arr[i].quest = courseData.id;
            }
            requests.quests.create_quest_speaker(arr)
                .then((res) => {
                    requests.quests.get_quest_speakers(courseData.id)
                        .then((res) => {
                            setCourseData((prev) => ({...prev, ["speakers"]: res.data}));
                        })
                        .catch((err) => console.log(err, "err get_quest_speakers"));
                })
                .catch((err) => console.log(err, "err create_quest_speaker"));
        }
    };
    let changeSpeaker = (arr) => {
        if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                requests.quests.change_quest_speaker(arr[i].id, arr[i])
                    .then((res) => {
                    })
                    .catch((err) => console.log(err, "err change_quest_speaker"));
            }
        }
    };
    let createGroups = (arr) => {
        let copyArr = [...arr];
        if (courseData.id && copyArr && copyArr.length > 0) {
            for (let i = 0; i < copyArr.length; i++) {
                copyArr[i].quest = courseData.id;
            }
            requests.quests.create_quest_groups(copyArr)
                .then((res) => {
                    requests.quests.get_quest_groups(courseData.id)
                        .then((res) => {
                            setCourseData((prev) => ({...prev, ["groups"]: res.data}));
                        })
                        .catch((err) => console.log(err, "err get_quest_groups"));
                })
                .catch((err) => console.log(err, "err create_quest_groups"));
        }
    };
    let changeGroups = (arr) => {
        if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                delete arr[i].quest;
                requests.quests.change_quest_group(arr[i].id, arr[i])
                    .then((res) => {
                    })
                    .catch((err) => console.log(err, "err change_quest_group"));
            }
        }
    };
    let createSuits = (arr) => {
        let copyArr = [...arr];
        if (courseData.id) {
            for (let i = 0; i < copyArr.length; i++) {
                copyArr[i].quest = courseData.id;
            }
            requests.quests.create_quest_suits(copyArr)
                .then((res) => {
                    requests.quests.get_quest_suits(courseData.id)
                        .then((res) => {
                            setCourseData((prev) => ({...prev, ["suits"]: res.data}));
                        })
                        .catch((err) => console.log(err, "err get_quest_suits"));
                })
                .catch((err) => console.log(err, "err create_quest_suits"));
        }
    };
    let changeSuits = (arr) => {
        if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                delete arr[i].quest;
                requests.quests.change_quest_suits(arr[i].id, arr[i])
                    .then((res) => {
                    })
                    .catch((err) => console.log(err, "err change_quest_suits"));
            }
        }
    };
    let createLearn = (arr) => {
        let copyArr = JSON.parse(JSON.stringify(arr));
        if (courseData.id) {
            for (let i = 0; i < copyArr.length; i++) {
                requests.quests.create_quest_learn([{
                    quest: courseData.id,
                    speciality_tag: copyArr[i].speciality_tag.id,
                    description: copyArr[i].description
                }])
                    .then((res) => {
                        requests.quests.get_quest_learn(courseData.id)
                            .then((res) => {
                                setCourseData((prev) => ({...prev, ["learn"]: res.data}));
                            })
                            .catch((err) => console.log(err, "err get_quest_learn"));
                    })
                    .catch((err) => console.log(err, "err create_quest_learn"));
            }
        }
    };
    let changeLearn = (arr) => {
        if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                let obj = {
                    speciality_tag: arr[i].speciality_tag.id,
                    description: arr[i].description
                };
                requests.quests.change_quest_learn(arr[i].id, obj)
                    .then((res) => {
                    })
                    .catch((err) => console.log(err, "err change_quest_learn"));
            }
        }
    };

    // универсальная функция проверки, нужно ли создание (спикеров, групп..),
    // или нужно редактирование (спикеров, групп..)
    let forApiSend = (arr) => {
        let copyArr = JSON.parse(JSON.stringify(arr));
        let arrCreate = [];
        let arrChange = [];
        if (arr && arr.length > 0) {
            arrCreate = copyArr.filter(x => typeof x.id === "undefined");
            arrChange = copyArr.filter(x => typeof x.id !== "undefined");
        }
        return {
            arrCreate,
            arrChange
        };
    };

    // основное api для создания курса.
    let handleSendCourse = (isEnabled, current = "", obj = false) => {
        let tagsArr = [];
        if (courseData.speciality_tag.length > 0) {
            for (let i = 0; i < courseData.speciality_tag.length; i++) {
                tagsArr = [...tagsArr, courseData.speciality_tag[i].id];
            }
        }

        let objSend = {
            name: courseData?.name,
            description: courseData?.description,
            dateBegin: convertDate(courseData?.dateBegin),
            dateEnd: convertDate(courseData?.dateEnd),
            speciality: courseData?.speciality,
            age: null,
            isEnabled: courseData?.isEnabled ? true : isEnabled,
            participatetype: courseData?.participateprice ? "PD" : "FR",
            participateprice: courseData?.participateprice || 0,
            academicHours: courseData?.academicHours || null,
            phone: courseData?.phone,
            isCorporate: courseData?.isCorporate,
            isForChild: false,
            y_image: courseData?.y_image,
            demo_video: courseData?.demo_youtube ? '' : courseData?.demo_video,
            demo_video_name: courseData.demo_video_name,
            demo_youtube: courseData?.demo_youtube,
            email: courseData?.email,
            speciality_tag: tagsArr,
            y_image_name: courseData?.y_image_name,
            banner: courseData?.banner,
            banner_name: courseData?.banner_name,
            banner_position: courseData?.banner_position,
            content_description: courseData?.content_description,
            access_after_begin: courseData?.access_after_begin,
            show_comments: courseData?.show_comments
        };
        if (!objSend.dateBegin) delete objSend.dateBegin;
        if (!objSend.dateEnd) delete objSend.dateEnd;
        if (courseData.isCorporate) objSend.isEnabled = true;

        // groups
        if (forApiSend(courseData.groups).arrCreate.length > 0) {
            createGroups(forApiSend(courseData.groups).arrCreate);
        }
        if (forApiSend(courseData.groups).arrChange.length > 0) {
            changeGroups(forApiSend(courseData.groups).arrChange);
        }
        // speaker
        if (courseData.speakers) {
            let newArrSpeakers = [...courseData.speakers];
            if (current === "speaker" && obj !== false) {
                // из шаблона спикера
                newArrSpeakers = [...newArrSpeakers, obj];
            }
            if (forApiSend(newArrSpeakers).arrCreate.length > 0) {
                createSpeaker(forApiSend(newArrSpeakers).arrCreate);
            }
            if (forApiSend(courseData.speakers).arrChange.length > 0) {
                changeSpeaker(forApiSend(courseData.speakers).arrChange);
            }
        }
        // suits
        if (courseData.suits) {
            let newArrSuits = [...courseData.suits];
            if (current === "suits" && obj !== false) {
                // из шаблона "кому подойдёт"
                newArrSuits = [...newArrSuits, obj];
            }
            if (forApiSend(newArrSuits).arrCreate.length > 0) {
                createSuits(forApiSend(newArrSuits).arrCreate);
            }
            if (forApiSend(courseData.suits).arrChange.length > 0) {
                changeSuits(forApiSend(courseData.suits).arrChange);
            }
        }
        // learn
        if (courseData.learn) {
            let newArrlearn = [...courseData.learn];
            if (current === "learn" && obj !== false) {
                // из шаблона "кому подойдёт"
                newArrlearn = [...newArrlearn, obj];
            }
            if (forApiSend(newArrlearn).arrCreate.length > 0) {
                createLearn(forApiSend(newArrlearn).arrCreate);
            }
            if (forApiSend(courseData.learn).arrChange.length > 0) {
                changeLearn(forApiSend(courseData.learn).arrChange);
            }
        }

        // remove Speaker
        if (arrRemoveSpeaker && arrRemoveSpeaker.length > 0) {
            for (let i = 0; i < arrRemoveSpeaker.length; i++) {
                if (arrRemoveSpeaker[i].id) {
                    requests.quests.remove_quest_speaker(arrRemoveSpeaker[i].id)
                        .then((res) => {
                            setArrRemoveSpeaker([]);
                        })
                        .catch((err) => console.log(err, "err remove skill"));
                }
            }
        }
        // remove learn
        if (arrForRemoveLearn && arrForRemoveLearn.length > 0) {
            let tmpArray = [];

            function itemCheck(item) {
                if (tmpArray.indexOf(item.id) === -1) {
                    tmpArray.push(item.id);
                    return true;
                }
                return false;
            }

            let arr = arrForRemoveLearn.filter((item) => item.id && itemCheck(item));
            if (arr && arr.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                    requests.quests.remove_quest_learn(arr[i].id)
                        .then((res) => {
                            setArrForRemoveLearn([]);
                        })
                        .catch((err) => console.log(err, "err remove skill"));
                }
            }

        }
        // remove group
        if (arrForRemoveGroup && arrForRemoveGroup.length > 0) {
            for (let i = 0; i < arrForRemoveGroup.length; i++) {
                requests.quests.remove_quest_group(arrForRemoveGroup[i])
                    .then((res) => {
                        setArrForRemoveGroup([]);
                    })
                    .catch((err) => console.log(err, "err remove skill"));
            }
        }
        // remove suits
        if (arrRemoveSuits && arrRemoveSuits.length > 0) {
            for (let i = 0; i < arrRemoveSuits.length; i++) {
                if (arrRemoveSuits[i].id) {
                    requests.quests.remove_quest_suits(arrRemoveSuits[i].id)
                        .then((res) => {
                            setArrRemoveSuits([]);
                        })
                        .catch((err) => console.log(err, "err remove skill"));
                }
            }
        }

        if (responseDataId) {
            requests.quests
                .edit_quest_new(responseDataId.ses, objSend)
                .then(() => {
                        if (isEnabled) {
                            message.success(variable_7);
                            setTimeout(() => {
                                history("/backoffice/my-course");
                            }, 1000);
                        } else message.success(variable_8);
                    }
                )
                .catch((e) => {
                    console.log(e, "error add_quest");
                    message.error(variable_9);
                });
        } else {
            requests.quests
                .add_quest_new(objSend)
                .then((response) => {
                    setResponseDataId(response.data);
                    setCourseData((prev) => ({...prev, ["id"]: response.data.id}));
                    if (isEnabled) {
                        message.success(variable_7);
                        history("/backoffice/my-course");
                    } else message.success(variable_8);
                })
                .catch((e) => {
                    console.log(e, "error add_quest");
                    message.error(variable_10);
                });
        }
        // удалить из учебного плана использованные скиллы
        if(trajectorySkills && trajectorySkills.length > 0)
        requests.auth
            .patch_organization_info({planning_skills: defaultFuncAndData.convertSkillId(trajectorySkills)})
            .catch((e) => console.error(e));
    };

    // yandex keys
    let fetchCardImageKey = () => {
        requests.storage.get_yandex_quest_key()
            .then((v) => {
                setYandexKeyCardImage(v.data.key);
            });
    };
    let fetchVideoPreviewKey = () => {
        requests.storage.get_yandex_quest_key()
            .then((v) => {
                setYandexKeyVideoPreview(v.data.key);
            });
    };
    let fetchBannerImageKey = () => {
        requests.storage.get_yandex_quest_key()
            .then((v) => {
                setYandexKeyBannerImage(v.data.key);
            });
    };

    useEffect(() => {
        if (typeof params.quest_id !== "undefined") {
            requests.quests.get_quest_new(params.quest_id)
                .then((res) => {
                    setCourseData(res.data);
                    setResponseDataId(res.data);
                });
        }
        fetchCardImageKey();
        fetchVideoPreviewKey();
        fetchBannerImageKey();
    }, []);
    useEffect(() => {
        stepsRef?.current?.scrollTo({top: 0, left: 0});
    }, [currentStep])
    useEffect(() => {
        if (courseData.id) {
            if (!courseData["speakers"]) {
                requests.quests.get_quest_speakers(courseData.id.toString())
                    .then((res) => {
                        setCourseData((prev) => ({...prev, ["speakers"]: res.data}));
                    })
                    .catch((err) => console.log(err, "err get_quest_speakers"));
            }
            if (!courseData["groups"]) {
                requests.quests.get_quest_groups(courseData.id)
                    .then((res) => {
                        let newArr = [...res.data.results];
                        if (newArr && newArr.length > 0) {
                            // удалить дефолтную группу, она не нужна на клиенте
                            for (let i = 0; i < newArr.length; i++) {
                                if (newArr[i].name === "Без группы") {
                                    newArr.splice(i, 1);
                                    break;
                                }
                            }
                            setCourseData((prev) => ({...prev, ["groups"]: newArr}));
                        }
                    })
                    .catch((err) => console.log(err, "err get_quest_groups"));
            }
            if (!courseData["suits"]) {
                requests.quests.get_quest_suits(courseData.id)
                    .then((res) => {
                        setCourseData((prev) => ({...prev, ["suits"]: res.data}));
                    })
                    .catch((err) => console.log(err, "err get_quest_suits"));
            }
            if (!courseData["learn"]) {
                requests.quests.get_quest_learn(courseData.id)
                    .then((res) => {
                        setCourseData((prev) => ({...prev, ["learn"]: res.data}));
                    })
                    .catch((err) => console.log(err, "err get_quest_learn"));
            }
        }
    }, [courseData.id]);
    useEffect(() => {
        if (responseDataId !== null && courseData.id === null) {
            apiCreateSpeaker();
            apiCreateGroup();
            apiCreateSuits();
            apiCreateLearn();
        }
    }, [responseDataId]);
    return (
        <div className={"add_course-parent"}>
            <div className="group" ref={stepsRef}>
                {(trial_status === 0 || trial_status === 1) && <Trial/>}
                {params?.quest_id &&
                    <div className="top_navigation">
                        <div
                            className="btn_prev"
                            style={(trial_status === 0 || trial_status === 1) ? {
                                marginTop: 10,
                                cursor: "pointer"
                            } : {cursor: "pointer"}}
                            onClick={() => history("/backoffice/my-course")}
                        >
                            <Arrow/> <span> {variable__3}</span>
                        </div>
                        {/*<div className="group">*/}
                        {/*    <div onClick={() => history("/backoffice/view-course/" + params?.quest_id)}>*/}
                        {/*        {variable_19}*/}
                        {/*    </div>*/}
                        {/*    <div className={"active"}>*/}
                        {/*        {variable_20}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="empty"/>
                    </div>
                }
                <div className="h2">{params?.quest_id ? variable_1 : variable_2}</div>
                <div>
                    <Tabs
                        defaultActiveKey="current-course"
                        activeKey={activeTab}
                        destroyInactiveTabPane={true}
                        items={[
                            {
                                key: "current-course",
                                label: "Информация",
                                // icon: <Folder />,
                                children: <Outlet />
                            },
                            {
                                key: "view-course",
                                label: "Материалы",
                                // icon: <Folder />,
                                children: <Outlet />
                            },
                            // {
                            //     key: "feedback-course",
                            //     label: "Обратная связь",
                            //     icon: <Folder />,
                            //     children: <Outlet />
                            // },
                        ]}
                        onChange={(activeKey) => ({
                            "current-course": () => history("/backoffice/current-course/" + params?.quest_id, { from: pathname }),
                            "view-course": () => {
                                if ( (currentStep === 0 || currentStep === 1) && (!quest_id_ses || quest_id_ses === 'undefined') ) return message.warn('Прежде чем перейти к редактированию материалов, нужно заполнить и сохранить информацию о курсе')
                                history("/backoffice/view-course/" + params?.quest_id, {from: pathname})
                            },
                            "feedback-course": () => history("/backoffice/feedback-course/" + params?.quest_id, { from: pathname })
                        }[activeKey]())}
                    />
                </div>
                <Popconfirm
                    placement="left"
                    title={variable_3}
                    okText={variable_4}
                    cancelText={variable_5}
                    onConfirm={() => deleteCourse()}>
                    <Tooltip placement="left" title="Удалить курс">
                        <div className="remove_course">
                            <div className="group_image">
                                <RemoveCourse1/>
                                <RemoveCourse2/>
                            </div>
                        </div>
                    </Tooltip>
                </Popconfirm>
                <div className="inner">
                    <div className="steps">
                        <div className={currentStep === 0 ? "step active" : "step"}>
                            <span>1</span>
                        </div>
                        <div className="line"></div>
                        <div className={currentStep === 1 ? "step active" : "step"}>
                            <span>2</span>
                        </div>
                        <div className="line"/>
                        <div className={currentStep === 2 ? "step active" : "step"}>
                            <span>3</span>
                        </div>
                    </div>
                    {currentStep === 0 &&
                        <Step1
                            courseData={courseData}
                            yandexKeyCardImage={yandexKeyCardImage}
                            yandexKeyVideoPreview={yandexKeyVideoPreview}
                            yandexKeyBannerImage={yandexKeyBannerImage}
                            fetchCardImageKey={fetchCardImageKey}
                            fetchBannerImageKey={fetchBannerImageKey}
                            setActiveSpecialId={(indx) => {
                                setCourseData((prev) => ({...prev, ["speciality"]: indx}));
                            }}
                            setNameProps={(name) => {
                                setCourseData((prev) => ({...prev, ["name"]: name}));
                            }}
                            setAboutProps={(description) => {
                                setCourseData((prev) => ({...prev, ["description"]: description}));
                            }}
                            setLinkYoutubeProps={(link) => {
                                setCourseData((prev) => ({...prev, ["demo_youtube"]: link}));
                                setCourseData((prev) => ({...prev, ["demo_video"]: ''}));
                                setCourseData((prev) => ({...prev, ["demo_video_name"]: ''}));
                            }}
                            setLinkDemoFileProps={(link) => {
                                setCourseData((prev) => ({...prev, ["demo_video"]: link}));
                            }}
                            setDateBegin={(begin) => {
                                setCourseData((prev) => ({...prev, ["dateBegin"]: begin}));
                            }}
                            setDateEnd={(end) => {
                                setCourseData((prev) => ({...prev, ["dateEnd"]: end}));
                            }}
                            setAcademHourseProps={(hourse) => {
                                setCourseData((prev) => ({...prev, ["academicHours"]: hourse}));
                            }}
                            setCorporate={(isCorp) => {
                                setCourseData((prev) => ({...prev, ["isCorporate"]: isCorp}));
                            }}
                            setCostCourseProps={(cost) => {
                                setCourseData((prev) => ({...prev, ["participateprice"]: cost}));
                            }}
                            setTagsToProps={(obj, isRemove) => {
                                if (isRemove) {
                                    let newArr = {...courseData};
                                    if (newArr.speciality_tag && newArr.speciality_tag.length > 0) {
                                        for (let i = 0; i < newArr.speciality_tag.length; i++) {
                                            if (newArr.speciality_tag[i].id === obj.id) {
                                                newArr.speciality_tag = newArr.speciality_tag.filter(x => x.id !== obj.id);
                                                break;
                                            }
                                        }
                                    }
                                    if (newArr.learn && newArr.learn.length > 0) {
                                        let length = newArr.learn.length;
                                        for (let i = 0; i < length; i++) {
                                            if (newArr.learn[i].speciality_tag.id === obj.id) {
                                                if (newArr.learn[i].id) {
                                                    let copyId = JSON.parse(JSON.stringify(newArr.learn[i].id));
                                                    setArrForRemoveLearn((prev) => [...prev, {id: copyId}]);
                                                }
                                                newArr.learn = newArr.learn.filter(x => x.speciality_tag.id !== obj.id);
                                                break;
                                            }
                                        }
                                    }
                                    setCourseData(newArr);
                                } else {
                                    let newArr = {...courseData};
                                    newArr.speciality_tag = [...newArr.speciality_tag, obj];
                                    setCourseData(newArr);
                                }
                            }}
                            setGroupProps={(group) => {
                                setCourseData((prev) => ({...prev, ["groups"]: group}));
                            }}
                            setEmailFeedback={(email) => {
                                setCourseData((prev) => ({...prev, ["email"]: email}));
                            }}
                            setPhoneFeedback={(phone) => {
                                setCourseData((prev) => ({...prev, ["phone"]: phone}));
                            }}
                            setArrForRemoveGroup={(id) => {
                                setArrForRemoveGroup((prev) => [...prev, id]);
                            }}
                            setNextStep={() => setCurrentStep(1)}
                            setCourseImage={(link) => {
                                setCourseData((prev) => ({...prev, ["y_image"]: link}));
                            }}
                            setCourseImageName={(imageName) => {
                                setCourseData((prev) => ({...prev, ["y_image_name"]: imageName}));
                            }}
                            setDemoVideoName={(name) => {
                                setCourseData((prev) => ({...prev, ["demo_video_name"]: name}));
                                setCourseData((prev) => ({...prev, ["demo_youtube"]: ''}));
                            }}
                            setBanner={(url, name) => {
                                setCourseData((prev) => ({...prev, ["banner"]: url}));
                                setCourseData((prev) => ({...prev, ["banner_name"]: name}));
                            }}
                            setBannerPosition={(str) => {
                                setCourseData((prev) => ({...prev, ["banner_position"]: str}));
                            }}
                            setContentDescription={(str) => {
                                setCourseData((prev) => ({...prev, ["content_description"]: str}));
                            }}
                            activeModules={activeModules}
                            setAccessAfterBegin={(value) => {
                                setCourseData((prev) => ({...prev, ["access_after_begin"]: value}));
                            }}
                            setDisabledComments={(value) => {
                                setCourseData((prev) => ({...prev, ["show_comments"]: value}));
                            }}
                        />
                    }
                    {currentStep === 1 &&
                        <Step2
                            speakers={courseData?.speakers}
                            suits={courseData?.suits}
                            learn={courseData?.learn}
                            setOriginalSpeakerProps={(speakers) => {
                                setCourseData((prev) => ({...prev, ["speakers"]: speakers}));
                            }}
                            setOriginalSuitsProps={(suits) => {
                                setCourseData((prev) => ({...prev, ["suits"]: suits}));
                            }}
                            setOriginalLearnProps={(learn) => {
                                setCourseData((prev) => ({...prev, ["learn"]: learn}));
                            }}
                            setArrRemoveSpeaker={(speaker) => {
                                setArrRemoveSpeaker((prev) => [...prev, speaker]);
                            }}
                            setArrRemoveSuits={(elem) => {
                                setArrRemoveSuits((prev) => [...prev, elem]);
                            }}
                            setArrForRemoveLearn={(obj) => {
                                let newArr = {...courseData};
                                if (newArr.learn && newArr.learn.length > 0) {
                                    for (let i = 0; i < newArr.learn.length; i++) {
                                        if (newArr.learn[i].speciality_tag.id === obj.id) {
                                            newArr.learn = newArr.learn.filter(x => x.speciality_tag.id !== obj.id);

                                            break;
                                        }
                                    }
                                }
                                setArrForRemoveLearn((prev) => [...prev, obj]);
                                setCourseData(newArr);
                                // setArrForRemoveLearn((prev) => [...prev, learn])
                            }}
                            setSaveCourseProps={(obj, current) => {
                                setTimeout(() => {
                                    handleSendCourse(false, current, obj);
                                }, 100);
                            }}
                            tagsToProps={courseData?.speciality_tag}
                            setStep={() => setCurrentStep(0)}
                            setNextStep={() => setCurrentStep(2)}
                        />
                    }
                    {currentStep === 2 &&
                        <Step3
                            courseData={courseData}
                            originalSpeakerProps={courseData?.speakers}
                            originalSuitsProps={courseData?.suits}
                            originalLearnProps={courseData?.learn}
                            setStep={() => setCurrentStep(1)}
                            setPublicCourse={() => handleSendCourse(true)}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default CurrentCourse;