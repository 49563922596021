import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import cn from "classnames";
import {Image, Input, message, Spin, Upload} from "antd";
import requests from "../../../axios/requests";
import { defaultFuncAndData } from "../../../utils/defaultData";
import CommentCustom from "../../../components/reuseComponents/CommentCustom";
import variableLanguage from "../../../utils/hooks/hookGetVariablesLanguage";
import Button from "../../../components/reuseComponents/Button/Button";
import send_comment from "../../../../images/icons/send_comment.png";

const Article = (props) => {
    let {text, image_file, url_refs, id, task_file_name, task_file} = props;
    if (url_refs === "undefined") {
        url_refs = undefined;
    }

    const variable_5 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_9"});
    const variable_11 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_11"});
    const variable_14 = variableLanguage({keyPage: "courseManageViewLesson", keyVariable: "variable_14"});

    const [valueComment, setValueComment] = useState("");
    const [comments, setComments] = useState("");
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState("");

    let uploadProps = {
        onChange({file}) {
            if (file.status !== "uploading") {
            }
        },
        listType: "card",
        fileList: [
            {
                uid: "-1",
                name: fileName,
                status: "done",
                response: props.variable_26,
                url: file,

            }
        ],
        defaultFileList: [
            {
                uid: "1",
                name: task_file_name,
                status: "done",
                response: variable_11, // custom error message to show
                url: task_file,
            },
        ],
    };
    let getApiComments = () => {
        requests.comments.get_comments({
            contentType: 46,
            objectId: id
        })
            .then((res) => {
                setComments(res.data);
            })
            .catch((error) => console.log(error, "error get_comments"));
    };
    let sendComment = () => {
        if (!valueComment) {
            return;
        }
        let obj = {
            phone: props.profile.phone,
            user: {
                profile: {
                    avatar: props.profile.avatar || ""
                },
            },
            content_type: 46,
            object_id: id,
            text: valueComment
        };
        requests.comments.create_comment(obj)
            .then((res) => {
                setComments((prev) => [...prev, res.data]);
            })
            .catch((error) => console.log(error, "error add_comment"));
        setValueComment("");
    };
    let handleSetLikeDislake = (id, value) => {
        let obj = {
            "content_type": 86,
            "object_id": id,
            "value": value
        };
        requests.comments.like_dislike(obj)
            .then((res) => {
                getApiComments();
            })
            .catch((error) => console.log(error, "error set_like"))
            .finally();
    };
    let handleRemoveComment = (id) => {
        requests.comments.remove_comment(id)
            .then(() => {
                getApiComments();
                message.success(variable_7);
            })
            .catch(err => console.error(err));
    };
    let isVideo = (fileName) => {
        const videoFormats = [
            ".WEBM", ".MPG", ".MP2", ".MPEG", ".MPE", ".MPV",
            ".OGG", ".MP4", ".M4P", ".M4V", ".AVI", ".WMV",
            ".MOV", ".QT", ".FLV", ".SWF", "AVCHD"
        ];
        return (videoFormats.some((format) => fileName?.toLowerCase().includes(format.toLowerCase())));
    };

    useEffect(() => {
        getApiComments();
    }, [id]);
    useEffect(() => {
        if (task_file_name) {
            setFileName(task_file_name);
        }
        if (task_file) {
            setFile(task_file);
        }
    }, [task_file_name, task_file]);
    //VOICE
    const [showVoice, setShowVoice] = useState(false)
    const [records, setRecords] = useState([])
    const [genLoading, setGenLoading] = useState(false)
    const generateVoice = () => {
        setGenLoading(true)
        requests.voice_gen.synthesize_create({stage_theory: id})
            .then(res => {
                awaitVoiceList()
            })
            .catch(e => console.log('generateVoice Error: ', {e}))
    }
    const getVoiceList = () => {
        setGenLoading(true)
        requests.voice_gen.synthesize_list({stage_theory: id})
            .then(res => {
                if (res.data.length === 0) setShowVoice(false)
                else setShowVoice(true)
                setRecords(res.data)
                setGenLoading(false)
            })
            .catch(e => console.log('getVoiceList Error: ', {e}))
    }
    const awaitVoiceList = () => {
        let stopper = false;
        const existsCount = records.length
        const intervalId = setInterval(() => { // сохраняем ID интервала
            if (!stopper) {
                requests.voice_gen.synthesize_list({stage_theory: id})
                    .then(({data}) => {
                        if (data.length > existsCount) {
                            setRecords(data);
                            setGenLoading(false);
                            stopper = true;
                            clearInterval(intervalId); // останавливаем интервал
                        }
                    })
                    .catch(e => console.log('awaitVoiceList Error: ', {e}));
            }
        }, 5000);
    }

    useEffect(() => {
        setRecords([])
        getVoiceList()
    }, [id])

    return (
        <>
            <div className="article_container">
                <div className={"article_content"}>
                    {image_file && image_file !== "https://storage.yandexcloud.net/skill-storage/undefined"
                        ? <Image src={image_file} style={{maxWidth: "850px", borderRadius: 20}}/>
                        : <></>
                    }
                    {text &&
                        <>
                            <div className={"h4"}>{variable_8}</div>
                            <pre className="description">{defaultFuncAndData.linkChanger(text)}</pre>
                        </>
                    }
                    {task_file_name &&
                        <>
                            <div className={"h4"}>{variable_14}</div>
                            {isVideo(task_file_name) &&
                                <>
                                    {isVideo(task_file_name) && <div className="player-wrapper">
                                        <ReactPlayer
                                            controls
                                            className="react-player"
                                            url={task_file}
                                            width="100%"
                                            height="100%"
                                            style={{maxHeight: 600}}
                                        />
                                    </div>
                                    }
                                </>
                            }
                            {task_file_name.match(/\.(jpg|jpeg|png|gif)$/i) && task_file && task_file !== "https://storage.yandexcloud.net/skill-storage/undefined"
                                ?
                                <Image src={task_file} style={{maxWidth: "850px", borderRadius: 20}}/>
                                : <></>
                            }
                            {task_file_name &&
                                <div className={"video_file-name"}>{task_file_name}</div>
                            }
                            {/*<div>
                            <Upload {...uploadProps} disabled></Upload>
                        </div>*/}
                        </>
                    }
                    {url_refs &&
                        <>
                            <div className="url_title">{variable_9}</div>
                            <div className="url_ref">{defaultFuncAndData.linkChanger(url_refs)}</div>
                        </>
                    }
                </div>
                <div className={cn("article_content voice", {open: showVoice })}>
                    {records.length > 0 && records.map((el,i) => <div className="audio-item">
                        <div className="label">{el.stage_theory_title} ({++i})</div>
                        <audio controls>
                            <source src={el.file_path} type="audio/wav" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>)}
                    {genLoading && <Spin/>}
                </div>
            </div>

            <div className="voice_block">
                <Button disabled={genLoading} text={records.length ? 'Озвучить еще раз' : 'Озвучить статью'} click={() => {
                    generateVoice()
                    if (records.length === 0) setShowVoice(true)
                }}/>
            </div>

            {props?.show_comments &&
                <div style={{width: "100%", marginTop: 15}}>
                    {comments && comments.length > 0 &&
                        <div className="comment_title">
                            {variable_5}
                        </div>
                    }
                    {comments && comments.length > 0 &&
                        comments.map((com, indx) =>
                            <div key={indx}>
                                <CommentCustom
                                    {...com}
                                    setLike={(id) => handleSetLikeDislake(id, true)}
                                    setDislake={(id) => handleSetLikeDislake(id, false)}
                                    removeComment={handleRemoveComment}
                                />
                            </div>
                        )
                    }
                    <div className="comment_input">
                        <Input
                            placeholder={variable_6}
                            className={"input_classic"}
                            value={valueComment}
                            onChange={(e) => setValueComment(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) sendComment();
                            }}
                        />
                        <img src={send_comment} onClick={sendComment}/>
                    </div>
                </div>
            }
        </>
    );
};
export default Article;
