import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { motion } from "framer-motion/dist/framer-motion";
import { Transition } from "react-transition-group";
import requests from "../../axios/requests";

import InputWsButton from "../../components/reuseComponents/InputButton";
import ReuseDropDown from "../../components/reuseComponents/ReuseDropDown";
import ChangeLessonParent from "./ChangeContent/ChangeLessonParent";
import ViewLessonParent from "./ViewContent/ViewLessonParent";
import Trial from "../../components/reuseComponents/Trial";
import ModalInfo from "./ModalInfo/ModalInfo";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import {Input, message, Modal, Popconfirm, Spin, Tabs} from "antd";
import { defaultFuncAndData } from "../../utils/defaultData";

import edit_icon from "../../../images/icons/edit_icon.png";
import remove_icon from "../../../images/icons/remove_icon.png";
import add_lesson from "../../../images/icons/add_lesson.png";
import settings_mobile_category from "../../../images/icons/settings_mobile-category.png";
import close_modal_grey from "../../../images/icons/close_modal-grey.png";
import { ReactComponent as ViewCourse } from "../../../images/icons/view_course.svg";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as SearchCourseMobile } from "../../../images/icons/search_course-mobile.svg";
import { ReactComponent as Logo } from "../../../images/icons/logo.svg";
import { ReactComponent as Search } from "../../../images/icons/search_icon.svg";
import { ReactComponent as CopyIcon } from "../../../images/icons/copy_link.svg";

const CourseManage = () => {
    const history = useNavigate();
    const { pathname } = useLocation();
    const params = useParams();
    const quest_id_ses = params.id;
    const {trial_status} = useSelector(state => state.Reducer);

    const variable_1 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_3"});
    const variable_6 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_9"});
    const variable_10 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_10"});
    const variable_11 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_11"});
    const variable_12 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_12"});
    const variable_13 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_13"});
    const variable_14 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_14"});
    const variable_15 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_15"});
    const variable_16 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_16"});
    const variable_17 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_17"});
    const variable_19 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_19"});
    const variable_20 = variableLanguage({keyPage: "courseManage", keyVariable: "variable_20"});

    const [quest_id, set_quest_id] = useState(null);
    const [currentQuest, setCurrentQuest] = useState([]);
    const [stages, setStages] = useState([]);
    const [originalStages, setOriginalStages] = useState([]);
    const [searchLesson, setSearchLesson] = useState("");
    const [activeTheoryIndx, setActiveTheoryIndx] = useState(0);
    const [activeArrList, setActiveArrList] = useState(0);
    const [arrListLesson, setArrListLesson] = useState([]);
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const [baseAccess, setBaseAccess] = useState(false);

    const [flagOpenEdit, setFlagOpenEdit] = useState(null);
    const [flagOpenView, setFlagOpenView] = useState(null);
    const [flagOpenDropDown, setFlagOpenDropDown] = useState(false);
    const [flagOpenSearchMobile, setFlagOpenSearchMobile] = useState(false);
    const [flagModalSettingsMobile, setFlagModalSettingsMobile] = useState(false);
    const [flagHiddenFotter, setFlagHiddenFooter] = useState(null);
    const [render, setRender] = useState(1);
    const [isEdit, setIsEdit] = useState(true);

    const inputSearchRef = useRef();
    const innerBlockRef = useRef();

    let deleteLesson = (stage_id) => {
        const newStages = stages.filter((stage) => stage.id !== stage_id);
        setStages(newStages);
        requests.stages.delete_stage(stage_id).then((res) => {
            if (res.status === 204) {
                const newStages = stages.filter((stage) => stage.id !== stage_id);
                setStages(newStages);
                message.success(variable_15);
            }
            return res;
        });
    };
    let addedLesson = () => {
        requests.stages
            .stage_add({
                name: "",
                post: "",
                quest: quest_id,
            })
            .then(() => {
                message.success(variable_16);
                getApiStages();
            })
            .catch((e) => console.error(e));
    }
    let handleAddLesson = () => {
        if ((typeof baseAccess?.status !== "undefined" && !baseAccess.status))
            setOpenModalInfo(true)
        else
            addedLesson()
    };
    let getApiBaseAccess = () => {
        requests.organization.has_base_acccess()
            .then(res => setBaseAccess(res.data))
    }
    let getApiQuestId = () => {
        requests.quests
            .get_quest_new(quest_id_ses)
            .then((response) => {
                setCurrentQuest(response.data);
                set_quest_id(response.data.id);
                setRender((prev) => prev + 1);
            })
            .catch((e) => console.error(e));
    };
    let getApiStages = (words = "") => {
        requests.stages
            .get_stages_quest(quest_id, words)
            .then(({data}) => {
                let arr = [variable_17];
                for (let i = 0; i < data.length; i++) {
                    arr = [...arr, data[i].name];
                }
                setArrListLesson(arr);
                setStages(data);
                setOriginalStages(data);
            })
            .catch((e) => console.error(e));
    };

    useEffect(() => {
        getApiQuestId();
        getApiBaseAccess();
    }, []);
    useEffect(() => {
        if (quest_id)
            getApiStages();
    }, [quest_id]);
    useEffect(() => {
        if (activeArrList !== 0)
            setStages([originalStages[activeArrList - 1]]);
        else
            setStages(originalStages);
    }, [activeArrList]);
    useEffect(() => {
        if (flagOpenView === null && quest_id)
            getApiStages();
    }, [flagOpenView])
    return (
        <div className={"course_manage"}>
            <div ref={innerBlockRef} className="inner_right">
                {(trial_status === 0 || trial_status === 1) && <Trial/>}
                <div className={flagOpenSearchMobile ? "top_filter-mobile hidden" : "top_filter-mobile"}>
                    <div className="icon">
                        <Logo
                            width={38}
                            height={38}
                            onClick={() => window.open("https://skillometer.ru/", 'blank')}
                        />
                    </div>
                    <div className="filters_mobile">
                        <div
                            onClick={() => {
                                requests.quests.make_a_link(quest_id).then((link) => {
                                    let newLink = link.data.link.replace("/catalog/corp-preview?ses=", "/course-preview/");
                                    navigator.clipboard.writeText(newLink);
                                    message.success(variable_1, 0.5);
                                });
                            }}
                            className="btn_share"
                        >
                            <div><CopyIcon/></div>
                        </div>
                        <div className="search_"
                             onClick={() => {
                                 setFlagOpenSearchMobile(true);
                                 setTimeout(() => {
                                     inputSearchRef.current.focus();
                                 }, 100);
                             }}
                        >
                            <SearchCourseMobile/>
                        </div>
                    </div>
                    <div className={flagOpenSearchMobile ? "search_mobile active" : "search_mobile"}>
                        <div className={"svg"}><Search/></div>
                        <Input
                            ref={inputSearchRef}
                            className={"input_classic input_search-mobile"}
                            placeholder={""}
                            value={searchLesson}
                            onChange={(e) => {
                                setSearchLesson(e.target.value);
                                getApiStages(e.target.value);
                            }}
                        />
                        <span onClick={() => {
                            setFlagOpenSearchMobile(false);
                            setSearchLesson("");
                            getApiStages();
                        }}>{variable_2}</span>
                    </div>
                    <div className="settings" style={{opacity: 0}}/>

                </div>
                <div className="top_navigation">
                    <div
                        className="btn_prev"
                        style={(trial_status === 0 || trial_status === 1) ? {
                            marginTop: 10,
                            cursor: "pointer"
                        } : {cursor: "pointer"}}
                        onClick={() => history("/backoffice/my-course")}
                    >
                        <Arrow/> <span> {variable_3}</span>
                    </div>
                    {/*<div className="group">*/}
                    {/*    <div*/}
                    {/*        className={"active"}*/}
                    {/*    >*/}
                    {/*        {variable_19}*/}
                    {/*    </div>*/}
                    {/*    <div onClick={() => history("/backoffice/current-course/" + quest_id_ses)}>*/}
                    {/*        {variable_20}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="empty"/>
                </div>
                <div className="top_title">
                    <span style={{fontSize: 32}}>{currentQuest.name} </span>
                </div>
                <div>
                    <Tabs
                        defaultActiveKey="view-course"
                        destroyInactiveTabPane={true}
                        items={[
                            {
                                key: "current-course",
                                label: "Информация",
                                // icon: <Folder />,
                                children: <Outlet />
                            },
                            {
                                key: "view-course",
                                label: "Материалы",
                                // icon: <Folder />,
                                children: <Outlet />
                            },
                            // {
                            //     key: "feedback-course",
                            //     label: "Обратная связь",
                            //     icon: <Folder />,
                            //     children: <Outlet />
                            // },
                        ]}
                        onChange={(activeKey) => ({
                            "current-course": () => history("/backoffice/current-course/" + quest_id_ses, { from: pathname }),
                            "view-course": () => history("/backoffice/view-course/" + quest_id_ses, { from: pathname }),
                            "feedback-course": () => history("/backoffice/feedback-course/" + quest_id_ses, { from: pathname })
                        }[activeKey]())}
                    />
                </div>
                <div className={"lesson_block"}>
                    <div className="group_block">
                        <div
                            onClick={handleAddLesson}
                            className="add_lesson"
                        >
                            <img
                                src={add_lesson}
                            />
                            <div>{variable_6}</div>
                        </div>
                        <div className="search">
                            <InputWsButton
                                value={searchLesson}
                                setValue={(e) => setSearchLesson(e)}
                                keyDown={(e) => {
                                    if (e.keyCode === 13)
                                        getApiStages(searchLesson);
                                }}
                                search={() => getApiStages(searchLesson)}
                            />
                        </div>
                        <ReuseDropDown
                            flagOpen={flagOpenDropDown}
                            setFlagOpen={(bool) => setFlagOpenDropDown(bool)}
                            arrList={arrListLesson}
                            activeArrList={activeArrList}
                            setActiveArrList={(indx) => setActiveArrList(indx)}
                        />
                        <div
                            onClick={() => {
                                requests.quests.make_a_link(quest_id)
                                    .then((link) => {
                                        setTimeout(async () => {
                                            const url = link.data.link.replace('catalog/corp-preview?ses=', 'course-preview/');
                                            await navigator.clipboard.writeText(url)
                                        })
                                        message.success(variable_1, 0.5);
                                    });
                            }}
                            className="btn_share"
                        >
                            <div><CopyIcon/></div>
                            <span>{variable_7}</span>
                        </div>
                    </div>
                    <DragDropContext
                        onDragEnd={result => {
                            const {source, destination} = result;
                            if (!destination)
                                return;

                            let arr = stages;
                            const [remove] = arr.splice(source.index, 1);
                            arr.splice(destination.index, 0, remove);
                            setStages(arr);
                            if (arr && arr.length > 0) {
                                for (let i = 0; i < arr.length; i++) {
                                    let obj = {
                                        name: arr[i].name,
                                        post: arr[i].post,
                                        sortOrder: i + 1
                                    };
                                    requests.stages.change_stages_quest(arr[i].id, obj)
                                        .catch(() => message.error(variable_8));
                                }
                            }
                            setRender((prev) => prev + 1);
                        }}
                    >
                        <Droppable droppableId="upflow">
                            {provided => {
                                return (
                                    (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {stages && stages.length > 0 &&
                                                stages.map((stage, indx) => {
                                                    return (
                                                        <Draggable
                                                            draggableId={stage.id.toString()}
                                                            index={indx}
                                                            key={stage.id}
                                                        >
                                                            {p => {
                                                                if (typeof p.draggableProps.onTransitionEnd === "function") {
                                                                    queueMicrotask(() =>
                                                                        p.draggableProps.onTransitionEnd?.({
                                                                            propertyName: "transform"
                                                                        })
                                                                    );
                                                                }
                                                                return (
                                                                    <>
                                                                        <motion.div
                                                                            className={(flagOpenEdit === indx || flagOpenView === indx)
                                                                                ? "lesson_row hide"
                                                                                : "lesson_row"
                                                                            }
                                                                            onClick={() => setFlagOpenView(indx)}
                                                                            variants={defaultFuncAndData.listAnimationDelay()}
                                                                            initial="hidden"
                                                                            animate="visible"
                                                                            custom={(indx) * 0.2}
                                                                            {...p.draggableProps}
                                                                            {...p.dragHandleProps}
                                                                            ref={p.innerRef}
                                                                            key={stage.id}
                                                                        >
                                                                            <span>{`${variable_9} ${indx + 1} — ${stage.name}`}</span>
                                                                            <div className="group_icon">
                                                                                {stage?.generation_in_progress &&
                                                                                    <Spin/>
                                                                                }
                                                                                <div className="eye">
                                                                                    <ViewCourse/>
                                                                                </div>
                                                                                <div
                                                                                    className="img_edit"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setFlagOpenEdit(indx);
                                                                                    }}
                                                                                >
                                                                                    <img src={edit_icon}/>
                                                                                </div>
                                                                                <div
                                                                                    className="img_remove"
                                                                                    onClick={(event) => event.stopPropagation()}
                                                                                >
                                                                                    <Popconfirm
                                                                                        title={variable_10}
                                                                                        onConfirm={() => deleteLesson(stage.id)}
                                                                                        okText={variable_11}
                                                                                        cancelText={variable_12}
                                                                                    >
                                                                                        <img src={remove_icon}/>
                                                                                    </Popconfirm>
                                                                                </div>
                                                                            </div>
                                                                        </motion.div>
                                                                        <Transition
                                                                            in={flagOpenEdit !== null && flagOpenEdit === indx}
                                                                            timeout={400}
                                                                            unmountOnExit
                                                                            mountOnEnter
                                                                        >
                                                                            {state => (
                                                                                <ChangeLessonParent
                                                                                    setIsEdit={setIsEdit}
                                                                                    isEdit={isEdit}
                                                                                    onClick={getApiStages}
                                                                                    stageName={stage.name}
                                                                                    stage_id={stage.id}
                                                                                    lessonNumber={indx + 1}
                                                                                    lessonName={stage.name}
                                                                                    activeProps={activeTheoryIndx}
                                                                                    transitionClass={state}
                                                                                    propsSetView={(ind) => {
                                                                                        setFlagOpenView(flagOpenEdit);
                                                                                        setFlagOpenEdit(null);
                                                                                        setActiveTheoryIndx(ind || 0);
                                                                                        getApiStages();
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Transition>
                                                                        <Transition
                                                                            in={flagOpenView !== null && flagOpenView === indx}
                                                                            timeout={400}
                                                                            unmountOnExit
                                                                            mountOnEnter
                                                                        >
                                                                            {(state) => (
                                                                                <ViewLessonParent
                                                                                    setIsEdit={setIsEdit}
                                                                                    stage={stage}
                                                                                    onClick={getApiStages}
                                                                                    activeProps={activeTheoryIndx}
                                                                                    lessonNumber={indx + 1}
                                                                                    closeLesson={() => setFlagOpenView(null)}
                                                                                    transitionClass={state}
                                                                                    propsSetEdit={(ind) => {
                                                                                        setFlagOpenEdit(flagOpenView);
                                                                                        setFlagOpenView(null);
                                                                                        setActiveTheoryIndx(ind || 0);
                                                                                    }}
                                                                                    propsSetView={() => {
                                                                                        setFlagOpenView(flagOpenEdit);
                                                                                        setFlagOpenEdit(null);
                                                                                    }}
                                                                                    showComments={currentQuest?.show_comments}
                                                                                />
                                                                            )}
                                                                        </Transition>
                                                                    </>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                        </div>
                                    )
                                );
                            }}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
            <div style={{position: "absolute", opacity: 0}}>{render}</div>
            <div
                className={flagOpenDropDown ? "overlay open" : "overlay"}
                onClick={() => {
                    setFlagOpenDropDown(false);
                }}
            />
            <ModalInfo
                openModalInfo={openModalInfo}
                setOpenModalInfo={() => {
                    setOpenModalInfo(false)
                }}
            />
            <>
                <Modal
                    visible={flagModalSettingsMobile}
                    footer={null}
                    className={"modal_settings-mobile"}
                    closable={false}
                    onCancel={() => {
                        setFlagHiddenFooter(null);
                        setFlagModalSettingsMobile(false);
                    }}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{padding: 15, backgroundColor: "#343843", borderRadius: 16}}
                >
                    <div className="title">
                        <div className="group_left">
                            <img src={settings_mobile_category}/>
                            <span>{variable_13}</span>
                        </div>
                        <div className="close" onClick={() => {
                            setFlagModalSettingsMobile(false);
                            setFlagHiddenFooter(null);
                        }}
                        >
                            <img src={close_modal_grey}/>
                        </div>
                    </div>
                    <div className="list">
                        {[variable_14].map((elem, indx) =>
                            <div
                                key={indx}
                                onClick={() => setFlagModalSettingsMobile(false)}
                            >
                                {elem}
                            </div>
                        )}
                    </div>
                </Modal>
            </>
        </div>
    )
}

export default CourseManage;