import { useNavigate, useLocation, Outlet } from "react-router-dom";
import {message, Tabs, Input} from "antd";
// import { motion } from "framer-motion";
// import { ReactComponent as Folder } from "@/assets/images/icons/folder.svg";
import { ReactComponent as Close } from "./../../../images/icons/close_x.svg";
import { ReactComponent as Basket } from "./../../../images/icons/basket.svg";
import { ReactComponent as Size } from "./../../../images/generate_course/size_icon.svg";
import { ReactComponent as Size2 } from "./../../../images/generate_course/size_icon_2.svg";

import { ReactComponent as ProfReview } from "./../../../images/skillSet/prof_review.svg";
import { ReactComponent as ProfReviewActive } from "./../../../images/skillSet/prof_review_active.svg";
import { ReactComponent as CourseGenerate } from "./../../../images/skillSet/course_generate.svg";
import { ReactComponent as CourseGenerateActive } from "./../../../images/skillSet/course_generate_active.svg";
import { ReactComponent as Courses } from "./../../../images/skillSet/courses.svg";
import { ReactComponent as CoursesActive } from "./../../../images/skillSet/courses_active.svg";

// import { setUser, setUserTrajectory, setConfig, setShowBuyModal } from "./../../../redux/reducers//store/reducers/commonReducer";
import { setUser, setUserTrajectory } from "../../../redux/actions/userActions";
import { setConfig, setShowBuyModal } from "../../../redux/actions/actions";

import { setSelectedTag, setLoadingGpt, setLoadingIndx } from "../../../redux/actions/actions";
import ModalBuy from "../GenerateCourse/Modals/ModalBuy/ModalBuy";
import requests from "../../axios/requests";
import close_tag from "../../../images/search_profession/close_tag.svg";
import variableLanguage from "../../utils/hooks/hookGetVariablesLanguage";
import Button from "../../components/Button/Button";
// import Input from "@/common/Input/Input";
import Tag from "../../components/Tag/Tag";

import "./SkillSetContainer.scss"
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
// import {SortableContainer, SortableElement} from "react-sortable-hoc";
import cn from "classnames";
import FetchSkills from "../../components/FetchSkills/FetchSkills";
// import {arrayMoveImmutable} from "array-move";
// import {skillSetContainerReducer} from "../../../store/reducers/skillSetContainerReducer.js";

function SkillSetContainer() {
    const variable_2 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "myCourse", keyVariable: "variable_4"});
    const variables_9 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_9"});
    const variable_6 = variableLanguage({keyPage: "generateCourse", keyVariable: "variable_6"});
    const variable_15 = variableLanguage({keyPage: "generateCourse", keyVariable: "variable_15"});
    const variable_18 = variableLanguage({keyPage: "generateCourse", keyVariable: "variable_18"});
    const variable_19 = variableLanguage({keyPage: "generateCourse", keyVariable: "variable_19"});
    const variable_24 = variableLanguage({keyPage: "generateCourse", keyVariable: "variable_24"});
    const variable_25 = variableLanguage({keyPage: "generateCourse", keyVariable: "variable_25"});
    const variables_31 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_31"});
    const variable_34 = variableLanguage({keyPage: "generateCourse", keyVariable: "variable_34"});
    const variables_39 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_39"});
    const variable_40 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_40"});
    const variable_41 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_41"});
    const variable_42 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_42"});
    const variable_21 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_21'});

    const { pathname } = useLocation();
    const history = useNavigate();

    const {trajectorySkills, user, config, language, showModalBuy, selectedTag, loadingGpt, loadingIndx} = useSelector(state => state.Reducer);
    // const {selectedTag, loadingGpt, loadingIndx} = useSelector(state => state.);
    const dispatch = useDispatch()
    useEffect(() => {
        console.log('useEffect SkillSetContainer', {selectedTag})
    },[selectedTag])
    const [activeKey,setActiveKey] = useState("prof-review")// prof-review , ready-courses , generate-course, my-courses
    const [scaleList,setScaleList] = useState(false)

    const tabHandler = (key) => {
        setActiveKey(key)
        history(key, { from: pathname })
    }
    const checkPage = (arrKey) => {
        return arrKey.includes(activeKey)
    }

    // список навыков
    const [myAnimationHidden, setMyAnimationHidden] = useState(false);
    const [myAnimationFlagMove, setMyAnimationFlagMove] = useState(false);
    const [currentSkillAddTraektory, setCurrentSkillAddTraektory] = useState(null);
    let timeoutHiddenAnimation = () => {
        setMyAnimationHidden(false);
        setMyAnimationFlagMove(true);
        setTimeout(() => {
            setMyAnimationHidden(true);
            setMyAnimationFlagMove(false);
        }, 600);
    };
    let addTrajectorySkill = (elem, needTimeout = false) => {
        for (let i = 0; i < trajectorySkills.length; i++) {
            if (trajectorySkills[i].id === elem.id) {
                return message.warning(variables_31);
            }
        }
        let newArr = [...trajectorySkills];
        newArr = [...newArr, {id: elem.id, name: elem.name}];
        if (needTimeout) {
            setCurrentSkillAddTraektory(elem.name);
            timeoutHiddenAnimation();
            setTimeout(() => {
                dispatch(setUserTrajectory(newArr));
            }, 500);
        } else
            dispatch(setUserTrajectory(newArr));

        let obj = {name: elem.name};
        obj.index = trajectorySkills.length + 1;

        requests.auth
            .patch_organization_info({planning_skills: newArr.map((elem => elem.id))})//.trajectory_skill(obj)
            .then(() => message.success(variables_39))
            .catch((e) => console.error(e));
    };
    let removeTrajectorySkill = (skill) => {
        let newArr;
        for (let i = 0; i < trajectorySkills.length; i++) {
            if (trajectorySkills[i].id === skill.id) {
                newArr = trajectorySkills.filter(t => t.id !== skill.id);
                break;
            }
        }
        dispatch(setUserTrajectory(newArr));
        requests.auth
            .patch_organization_info({planning_skills: newArr.map((elem => elem.id))})
            .catch((e) => console.error(e));
    };
    // список навыков

    // список навыков gen
    const [valueInputTag, setValueInputTag] = useState('');
    const filterSearch = (el) => {
        //'Atlassian Jira'.toUpperCase().includes("atlass".toUpperCase())
        if (valueInputTag === '') return true
        if (el.name.toUpperCase().includes(valueInputTag.toUpperCase())) return true
    }

    // const [selectTag, setSelectTag] = useState("");
    const [allEducationUser, setAllEducationUser] = useState([]);
    // const [loadingGpt, setLoadingGpt] = useState(false);
    // const [loadingIndx, setLoadingIndx] = useState(null);
    const [currentListEducation, setCurrentListEducation] = useState([]);

    let checkExistTags = () => {
        return allEducationUser
            .filter(elem => elem.quest?.id)
            .map((elem) => elem.speciality_tag);
    };
    let getExistTags = () => {
        requests.users.get_plannings()
            .then(res => setAllEducationUser(res.data.results))
            .catch(err => console.error(err))
    }
    let fetchGptApi = async () => {
        await requests.auth.fetch_education_plan(selectedTag.id)
            .then(res => {
                let data = res.data?.results;
                setCurrentListEducation(data);
                dispatch(setLoadingGpt(false));
                dispatch(setLoadingIndx(null));
            })
            .catch(err => {
                console.log(err?.response);
            });
    };
    let recursyProgress = (task_id) => {
        /*setLoadingGpt(true);*/
        requests.auth.calery_progress(task_id)
            .then((res) => {
                let data = res.data;
                if (data.progress.current !== 100)
                    setTimeout(() => {
                        recursyProgress(task_id);
                    }, 2000);
                else {
                    dispatch(setLoadingGpt(true));
                    fetchGptApi();
                }
            });
    };
    let updateEducationPlan = (needLoading = false) => {
        if (user?.education_plan_limit === 0) {
            dispatch(setLoadingGpt(false));
            dispatch(setLoadingIndx(null));
            return message.error(variable_15);
        }
        if (needLoading) {
            dispatch(setLoadingGpt(true));
        }
        requests.auth.education_plan({speciality_tag: selectedTag.id})
            .then((res) => {
                recursyProgress(res.data.task_id);
            })
            .catch((err) => {
                dispatch(setLoadingGpt(false));
                if (err?.response?.data?.length > 0) {
                    err.response.data.forEach(elem => {
                        message.error(elem);
                    });
                }
            });
    };
    useEffect(() => {
        if (window.location.pathname === "/generate-course" && localStorage.getItem("token"))
            history("/backoffice/generate-course")

        getExistTags();
        // fetchTrajectory();
        // getProfileUser()
        requests.users.get_config()
            .then(res => {
                dispatch(setConfig(res.data))
                // set_page_size(res.data?.PLAN_GENERATION_UPDATE_LIMIT || 4);
            })
            .catch(err => console.error(err))
    }, [])
    // список навыков gen
    console.log('SkillSetContainer: ', {trajectorySkills})
    const label = (key) => {
        return (
            <div className="label">
                <div className="step">
                    {key === 'prof-review' && <div>Шаг 1</div>}
                    {key === 'generate-course' && <div>Шаг 2</div>}
                    {key === 'courses' && <div>Шаг 3</div>}
                </div>
                <div className="info">
                    <>
                        {key === 'prof-review' && !checkPage(['prof-review']) && <ProfReview/>}
                        {key === 'prof-review' && checkPage(['prof-review']) && <ProfReviewActive/>}
                        {key === 'generate-course' && !checkPage(['generate-course']) && <CourseGenerate/>}
                        {key === 'generate-course' && checkPage(['generate-course']) && <CourseGenerateActive/>}
                        {key === 'courses' && !checkPage(['courses']) && <Courses/>}
                        {key === 'courses' && checkPage(['courses']) && <CoursesActive/>}
                    </>
                    {key === 'prof-review' && <div>Обзор профессий</div>}
                    {key === 'generate-course' && <div>Генерация курса</div>}
                    {key === 'courses' && <div>Мои курсы</div>}
                </div>
            </div>
        )
    }

    return (
        <div className="SkillSetContainer">
            <div className="header">
                <h1 className="page_title">Курсы от Искусственного интеллекта</h1>
            </div>
            <div
                className="basket"
            >
                <div className="nav">
                    <div className="nav-item" onClick={() => tabHandler("prof-review")}>
                        <div className={cn("figure", {show: checkPage(['prof-review'])})}></div>
                        {label("prof-review")}
                    </div>
                    <div className="nav-item" onClick={() => tabHandler("generate-course")}>
                        <div className={cn("figure", {show: checkPage(['generate-course'])})}></div>
                        {label("generate-course")}
                    </div>
                    <div className="nav-item" onClick={() => tabHandler("courses")}>
                        <div className={cn("figure", {show: checkPage(['courses'])})}></div>
                        {label("courses")}
                    </div>
                </div>
                <div className="info">
                    <div className="top">
                        <div className="fs20">Ваши навыки</div>
                        {checkPage(['prof-review','courses']) && <div style={{width: '480px'}}>
                            <FetchSkills addTrajectorySkill={addTrajectorySkill}/>
                        </div>}
                        {checkPage(['generate-course']) && <>
                            <input
                                className="generate_course-input"
                                type="text"
                                onChange={(e) => setValueInputTag(e.target.value)}
                                value={valueInputTag}
                                placeholder={"Найти навык"}
                            />
                            {/*    <Input*/}
                            {/*        padding={"7px 15px"}*/}
                            {/*        value={valueInputTag}*/}
                            {/*        setValue={(e) => setValueInputTag(e.target.value)}*/}
                            {/*        backgroundColor={"var(--background_lighten-dark)"}*/}
                            {/*        placeholder={"Найти навык"}*/}
                            {/*        zIndex={4}*/}
                            {/*    /> */}
                            </>
                        }
                        <div className="card">
                            <div className="bottom_part">
                                <div className="courses_count">
                                    <div className="fs32">
                                        {user?.education_plan_limit || 0}
                                    </div>
                                    <div className="fs14">
                                        {variable_21}
                                    </div>
                                </div>
                                <div className="add fs14" onClick={() => dispatch(setShowBuyModal(true))}>
                                    <Basket/>
                                    <div>Пополнить</div>
                                </div>
                            </div>
                        </div>
                        <div className="size_button" onClick={() => setScaleList(!scaleList)}>
                            {scaleList ? <Size2 /> : <Size/>}
                        </div>
                    </div>
                    <div className="fs16">Вы можете создать план обучения по вашим текущим навыкам, либо посмотреть существующие вакансии и пополнить навыки из реальных вакансий.</div>
                    {checkPage(['prof-review','courses']) && trajectorySkills?.length > 0 &&


                        <div className={cn("skill_list", {open: scaleList})}>
                            {trajectorySkills.map((elem, indx) =>
                                <div
                                    key={indx}
                                    className={elem.has_generated_quest ? "skill without_img" : "skill"}
                                >
                                    <span>{elem.name}</span>
                                    {!elem.has_generated_quest &&
                                        <img
                                            onClick={() => removeTrajectorySkill(elem)}
                                            src={close_tag}
                                        />
                                    }
                                </div>
                            )}
                        </div>
                    }
                    {checkPage(['generate-course']) && <div className={cn("skill_list_gen", {open: scaleList} )}>
                        {/*<div className="info">*/}
                        {/*    <div className="figure"/>*/}
                        {/*    <div className="text">{variable_24}</div>*/}
                        {/*    <div className="figure"/>*/}
                        {/*    <div className="text">{variable_25}</div>*/}
                        {/*</div>*/}
                        {trajectorySkills?.length > 0 &&
                            <div className="list_tags">
                                {trajectorySkills.filter(filterSearch).map((elem) =>
                                    <Tag
                                        elem={elem}
                                        key={elem.id}
                                        cursorPointer={true}
                                        hideRemoveIcon={true}
                                        countCourses={elem.generated_quests_count}
                                        countPlanes={elem.education_plans_count}
                                        className={cn("", {
                                            color_active: selectedTag?.name === elem.name,
                                            exist_quest: checkExistTags().includes(elem.id)
                                        })}
                                        exist_quest={checkExistTags().includes(elem.id)}
                                        click={(event) => {
                                            event.stopPropagation();
                                            if (loadingIndx === null) {
                                                // setSelectTag(elem);
                                                dispatch(setSelectedTag(elem))
                                            } else {
                                                message.warning(variable_18);
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        }
                        {/*{trajectorySkills?.length === 0*/}
                        {/*    ? <Button*/}
                        {/*        text={variable_19}*/}
                        {/*        click={() => history("/")}*/}
                        {/*        margin={"auto 0 0 0"}*/}
                        {/*        padding={"7px 15px"}*/}
                        {/*        maxWidth={"100%"}*/}
                        {/*        backgroundColor={"var(--background_lighten-dark)"}*/}
                        {/*        boxShadow={"none"}*/}
                        {/*    />*/}
                        {/*    : <Button*/}
                        {/*        text={selectedTag?.name ? variable_6 : variable_34}*/}
                        {/*        click={() => updateEducationPlan(true)}*/}
                        {/*        margin={"auto 0 0 0"}*/}
                        {/*        padding={"7px 15px"}*/}
                        {/*        maxWidth={"100%"}*/}
                        {/*        backgroundColor={"var(--background_lighten-dark)"}*/}
                        {/*        boxShadow={"none"}*/}
                        {/*        disabled={(!selectedTag?.name || loadingGpt || loadingIndx) ||*/}
                        {/*            (currentListEducation?.length === config?.PLAN_GENERATION_UPDATE_LIMIT)}*/}
                        {/*    />*/}
                        {/*}*/}
                    </div>}
                </div>
            </div>
            <div className="container">
                <div className="navigation">
                    <div>
                        <Outlet />
                    </div>
                </div>
            </div>
            <ModalBuy
                openModalBuy={showModalBuy}
                setOpenModalBuy={(value) => dispatch(setShowBuyModal(value))}
                config={config}
            />
        </div>

    );
}

export default SkillSetContainer;